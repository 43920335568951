export class RangeCalculatorService {
    /**
     * Initialise les pourcentages de chaque type de main
     */
    static initHandPercentages() {
        if (this.HAND_PERCENTAGES.size > 0)
            return;
        // Calculer le pourcentage de chaque type de main
        const cardRanks = ['A', 'K', 'Q', 'J', 'T', '9', '8', '7', '6', '5', '4', '3', '2'];
        // Pour chaque combinaison possible
        for (let i = 0; i < cardRanks.length; i++) {
            for (let j = 0; j <= i; j++) {
                const card1 = cardRanks[i];
                const card2 = cardRanks[j];
                if (i === j) {
                    // Paires (ex: AA) - 6 combinaisons (4C2 = 6)
                    const hand = card1 + card2;
                    this.HAND_PERCENTAGES.set(hand, 6 / this.TOTAL_COMBOS * 100);
                }
                else {
                    // Mains suited (ex: AKs) - 4 combinaisons
                    const suitedHand = card2 + card1 + 's';
                    this.HAND_PERCENTAGES.set(suitedHand, 4 / this.TOTAL_COMBOS * 100);
                    // Mains offsuit (ex: AKo) - 12 combinaisons
                    const offsuitHand = card2 + card1 + 'o';
                    this.HAND_PERCENTAGES.set(offsuitHand, 12 / this.TOTAL_COMBOS * 100);
                }
            }
        }
    }
    /**
     * Calcule le pourcentage total d'une range en fonction des fréquences des mains
     * @param selectedHands Map des mains sélectionnées avec leurs fréquences pour chaque couleur
     * @returns Le pourcentage total que représente la range, et le détail par couleur
     */
    static calculateRangePercentage(selectedHands) {
        this.initHandPercentages();
        const percentagesByColor = new Map();
        let totalPercentage = 0;
        // Pour chaque main sélectionnée
        selectedHands.forEach((frequencies, hand) => {
            // Obtenir le pourcentage de base pour ce type de main
            const basePercentage = this.HAND_PERCENTAGES.get(hand) || 0;
            // Pour chaque couleur/fréquence associée à cette main
            frequencies.forEach(frequency => {
                // Calculer le pourcentage ajusté par la fréquence sélectionnée
                const adjustedPercentage = basePercentage * (frequency.frequency / 100);
                // Ajouter au total général
                totalPercentage += adjustedPercentage;
                // Ajouter au total par couleur
                const currentColorPercentage = percentagesByColor.get(frequency.name) || 0;
                percentagesByColor.set(frequency.name, currentColorPercentage + adjustedPercentage);
            });
        });
        // Arrondir les résultats à 2 décimales
        totalPercentage = parseFloat(totalPercentage.toFixed(2));
        percentagesByColor.forEach((value, key) => {
            percentagesByColor.set(key, parseFloat(value.toFixed(2)));
        });
        return {
            total: totalPercentage,
            byColor: percentagesByColor
        };
    }
}
// Constantes pour les calculs de combinaisons
RangeCalculatorService.TOTAL_COMBOS = 1326; // Total de combinaisons de 2 cartes (52 * 51 / 2)
RangeCalculatorService.HAND_PERCENTAGES = new Map();
