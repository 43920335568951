export class ModalService {
    /**
     * Vérifie si une modale est actuellement active
     */
    static hasActiveModal() {
        return this.activeModals.length > 0;
    }
    /**
     * Affiche une modale avec les paramètres spécifiés
     * Retourne la modale créée ou null si une modale est déjà ouverte
     */
    static showModal(params) {
        // Vérifier si une modale est déjà ouverte et que l'ouverture forcée n'est pas activée
        if (this.hasActiveModal() && params.forceOpen !== true) {
            console.warn('Une modale est déjà ouverte. La nouvelle modale ne sera pas affichée.');
            return null;
        }
        // Créer le conteneur de modales s'il n'existe pas encore
        if (!this.modalContainer) {
            this.modalContainer = document.createElement('div');
            this.modalContainer.className = 'custom-modal-container';
            document.body.appendChild(this.modalContainer);
        }
        // Créer l'overlay qui contiendra la modale
        const overlay = document.createElement('div');
        overlay.className = 'custom-modal-overlay';
        // Créer la modale
        const modal = document.createElement('div');
        modal.className = 'custom-modal';
        modal.setAttribute('role', 'dialog');
        modal.setAttribute('aria-modal', 'true');
        // Créer l'en-tête de la modale
        const header = document.createElement('div');
        header.className = 'custom-modal-header';
        // Ajouter le titre
        const titleElement = document.createElement('h2');
        const titleId = `modal-title-${Date.now()}`;
        titleElement.id = titleId;
        titleElement.className = 'custom-modal-title';
        titleElement.textContent = params.title;
        modal.setAttribute('aria-labelledby', titleId);
        header.appendChild(titleElement);
        // Ajouter un bouton de fermeture si closable est vrai ou non spécifié
        if (params.closable !== false) {
            const closeButton = document.createElement('button');
            closeButton.className = 'custom-modal-close-btn';
            closeButton.innerHTML = '&times;';
            closeButton.setAttribute('aria-label', 'Fermer');
            closeButton.addEventListener('click', () => {
                this.closeModal(modal);
                if (params.onClose) {
                    params.onClose();
                }
            });
            header.appendChild(closeButton);
        }
        // Ajouter l'en-tête à la modale
        modal.appendChild(header);
        // Ajouter le contenu
        const contentElement = document.createElement('div');
        contentElement.className = 'custom-modal-content';
        // Si le contenu est une chaîne, l'ajouter directement
        if (typeof params.content === 'string') {
            contentElement.innerHTML = params.content;
        }
        // Sinon si c'est un élément HTML, l'ajouter comme enfant
        else if (params.content instanceof HTMLElement) {
            contentElement.appendChild(params.content);
        }
        // Ajouter le contenu à la modale
        modal.appendChild(contentElement);
        // Ajouter la modale à l'overlay
        overlay.appendChild(modal);
        // Ajouter l'overlay au conteneur
        this.modalContainer.appendChild(overlay);
        // Gérer la fermeture en cliquant sur l'overlay (sauf si persistent est vrai)
        if (!params.persistent) {
            overlay.addEventListener('click', (event) => {
                if (event.target === overlay) {
                    this.closeModal(modal);
                    if (params.onClose) {
                        params.onClose();
                    }
                }
            });
        }
        // Désactiver l'interaction avec les éléments en dehors de la modale
        this.togglePageInteractivity(false);
        // Ajouter à la liste des modales actives
        this.activeModals.push(modal);
        // Appliquer une animation d'entrée
        requestAnimationFrame(() => {
            overlay.classList.add('active');
            modal.classList.add('active');
        });
        // Gérer la touche Echap pour fermer la modale
        if (params.closable !== false) {
            const handleKeyDown = (e) => {
                if (e.key === 'Escape') {
                    this.closeModal(modal);
                    if (params.onClose) {
                        params.onClose();
                    }
                    document.removeEventListener('keydown', handleKeyDown);
                }
            };
            document.addEventListener('keydown', handleKeyDown);
        }
        // Mettre le focus sur le premier élément focusable de la modale
        setTimeout(() => {
            this.focusFirstElement(modal);
        }, 100);
        return modal;
    }
    /**
     * Ferme une modale spécifique
     */
    static closeModal(modal) {
        const index = this.activeModals.indexOf(modal);
        if (index > -1) {
            // Récupérer l'overlay parent
            const overlay = modal.closest('.custom-modal-overlay');
            // Appliquer l'animation de sortie
            modal.classList.remove('active');
            if (overlay) {
                overlay.classList.remove('active');
            }
            // Supprimer après l'animation
            setTimeout(() => {
                if (overlay && overlay.parentElement) {
                    overlay.parentElement.removeChild(overlay);
                }
                // Retirer de la liste des modales actives
                this.activeModals.splice(index, 1);
                // Si c'était la dernière modale, supprimer le conteneur et réactiver la page
                if (this.activeModals.length === 0) {
                    if (this.modalContainer && this.modalContainer.parentElement) {
                        this.modalContainer.parentElement.removeChild(this.modalContainer);
                        this.modalContainer = null;
                    }
                    this.togglePageInteractivity(true);
                }
            }, 300);
        }
    }
    /**
     * Ferme toutes les modales actives
     */
    static closeAllModals() {
        [...this.activeModals].forEach(modal => {
            this.closeModal(modal);
        });
    }
    /**
     * Active ou désactive l'interactivité du reste de la page
     */
    static togglePageInteractivity(interactive) {
        document.querySelectorAll('body > *:not(.custom-modal-container)').forEach(el => {
            if (el instanceof HTMLElement) {
                if (interactive) {
                    el.removeAttribute('inert');
                }
                else {
                    el.setAttribute('inert', '');
                }
            }
        });
    }
    /**
     * Met le focus sur le premier élément focusable dans la modale
     */
    static focusFirstElement(modal) {
        const focusableElements = modal.querySelectorAll('button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])');
        if (focusableElements.length > 0) {
            focusableElements[0].focus();
        }
        else {
            modal.setAttribute('tabindex', '-1');
            modal.focus();
        }
    }
}
ModalService.activeModals = [];
ModalService.modalContainer = null;
