var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { startStimulusApp } from '@symfony/stimulus-bridge';
import '@symfony/ux-live-component';
import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.min.css';
import routeService from './core/http/RouteService';
const application = startStimulusApp(require.context('@symfony/stimulus-bridge/lazy-controller-loader!../../templates', true, /\.[tj]sx?$/));
window.Stimulus = application;
window.$ = window.jQuery = $;
// Initialise select2
document.addEventListener('DOMContentLoaded', () => {
    $('.select2.form-select').select2({
        placeholder: 'Rechercher...',
        allowClear: true,
    });
});
// Initialize api routes
document.addEventListener('DOMContentLoaded', () => __awaiter(void 0, void 0, void 0, function* () {
    yield routeService.loadRoutes();
}));
