var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Controller } from '@hotwired/stimulus';
import { NotificationService } from "@app/core/notification/NotificationService";
import { ModalService } from "@app/core/modal/ModalService";
import { RangeImportService } from "@app/range/RangeImportService";
import RangeExportService from "@app/range/RangeExportService";
import { RangeCalculatorService } from "@app/range/RangeCalculatorService";
import { RangeGroupService } from "@app/range/RangeGroupService";
class RangeSelectorController extends Controller {
    constructor() {
        super(...arguments);
        this.colors = [
            { name: 'Check', color: '#6aa84f', frequency: 0, locked: false },
            { name: 'Call', color: '#4a86e8', frequency: 0, locked: false },
            { name: 'Fold', color: '#f1c232', frequency: 0, locked: false },
            { name: 'Raise', color: '#ff5a5a', frequency: 0, locked: false },
            { name: '4bet bluff', color: '#fffc5a', frequency: 0, locked: false },
            { name: '4bet allin', color: '#ec8ce3', frequency: 0, locked: false }
        ];
        this.controllerName = 'components--range--range-selector--range-selector';
        this.selectedHands = new Map();
        this.activeColor = this.colors[0].name;
        this.isMouseDown = false;
        this.initialSelectionState = false;
        this.clickTimeout = null;
        this.selectRangeModal = null;
    }
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            this.updateDisplay();
            this.initializeColorSelector();
            this.updateRangeStats();
            this.initializeDualRangeSlider();
            window.addEventListener('mouseup', this.handleMouseUp.bind(this));
            window.addEventListener('mouseleave', this.handleMouseUp.bind(this));
            // Chargement automatique du groupe de ranges s'il est défini
            const rangeGroupId = this.rangeGroupIdValue;
            if (rangeGroupId) {
                yield this.loadRangeGroupById(rangeGroupId);
            }
        });
    }
    /**
     * Charge automatiquement un groupe de ranges spécifié par ID
     */
    loadRangeGroupById(groupId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!groupId)
                return;
            try {
                const result = yield RangeGroupService.getRangeGroup(groupId);
                if (result.success && result.data) {
                    // Nettoyer la sélection actuelle
                    this.selectedHands.clear();
                    // Mettre à jour les mains sélectionnées
                    result.data.hands.forEach((frequencies, hand) => {
                        this.selectedHands.set(hand, frequencies);
                    });
                    // Mettre à jour les couleurs si disponibles
                    if (result.data.colors && result.data.colors.length > 0) {
                        this.colors = result.data.colors;
                    }
                    // Mettre à jour le nom du groupe
                    this.rangeNameTarget.value = result.data.name;
                    // Mettre à jour l'affichage
                    this.updateColorsList();
                    this.updateDisplay();
                    NotificationService.showNotification({
                        type: 'success',
                        message: 'Groupe de ranges chargé avec succès',
                    });
                }
                else {
                    console.error('Erreur lors du chargement du groupe de ranges:', result.message);
                    NotificationService.showNotification({
                        type: 'error',
                        message: result.message || 'Erreur lors du chargement du groupe de ranges',
                    });
                }
            }
            catch (error) {
                console.error('Erreur lors du chargement du groupe de ranges:', error);
                NotificationService.showNotification({
                    type: 'error',
                    message: `Erreur lors du chargement: ${error instanceof Error ? error.message : 'Erreur inconnue'}`,
                });
            }
        });
    }
    disconnect() {
        window.removeEventListener('mouseup', this.handleMouseUp.bind(this));
        window.removeEventListener('mouseleave', this.handleMouseUp.bind(this));
    }
    handleMouseEnter(event) {
        if (!this.isMouseDown)
            return;
        const cell = event.currentTarget;
        if (this.forceFillTarget.checked) {
            this.handleForceFill(cell);
        }
        else {
            if (!this.checkFrequencyLimit(event)) {
                return;
            }
            this.updateHandState(cell);
        }
    }
    handleMouseUp(event) {
        if (!this.isMouseDown)
            return;
        this.isMouseDown = false;
        // Si l'événement vient d'une cellule, on s'assure de terminer la sélection
        if (event.target instanceof HTMLElement) {
            const cell = event.target.closest('[data-hand]');
            if (cell && cell.dataset.hand) {
                if (this.forceFillTarget.checked) {
                    this.handleForceFill(cell);
                }
                else {
                    this.updateHandState(cell);
                }
            }
        }
    }
    initializeColorSelector() {
        this.updateColorsList();
    }
    clearColor(event) {
        const button = event.currentTarget;
        const colorName = button.dataset.colorName;
        if (!colorName)
            return;
        // Vérifier si la couleur est verrouillée
        const colorData = this.colors.find(c => c.name === colorName);
        if (colorData && colorData.locked) {
            NotificationService.showNotification({
                type: 'error',
                message: 'Cette range est verrouillée. Déverrouillez-la avant de la modifier.',
            });
            return;
        }
        // Parcourir toutes les mains sélectionnées
        this.selectedHands.forEach((frequencies, hand) => {
            // Filtrer les fréquences pour supprimer celle avec le nom de couleur donné
            const updatedFrequencies = frequencies.filter(f => f.name !== colorName);
            if (updatedFrequencies.length === 0) {
                // Si plus aucune couleur, supprimer la main
                this.selectedHands.delete(hand);
            }
            else {
                // Sinon mettre à jour avec les fréquences restantes
                this.selectedHands.set(hand, updatedFrequencies);
            }
        });
        this.updateDisplay();
    }
    updateColorsList() {
        // Calculer les statistiques de la range pour obtenir les pourcentages par couleur
        const stats = RangeCalculatorService.calculateRangePercentage(this.selectedHands);
        const container = this.colorsContainerTarget;
        container.innerHTML = this.colors.map(color => {
            // Récupérer le pourcentage pour cette couleur (0 si non défini)
            const percentage = stats.byColor.get(color.name) || 0;
            const lockIcon = color.locked ? '🔒' : '🔓';
            const lockTitle = color.locked ? 'Déverrouiller cette range' : 'Verrouiller cette range';
            return `
            <div class="color-item ${color.name === this.activeColor ? 'color-item__active' : ''} ${color.locked ? 'color-item__locked' : ''}" 
                 style="background-color: ${color.color}"
                 data-color-name="${color.name}"
                 data-action="click->${this.controllerName}#selectColor"
            >
                <span 
                    class="color-item__name"
                    data-color-name="${color.name}"
                    data-action="dblclick->${this.controllerName}#startEditing"
                >${color.name} (${percentage.toFixed(0)}%)</span>
                <div class="color-item__actions">
                    <button 
                        data-action="click->${this.controllerName}#toggleLock"
                        data-color-name="${color.name}"
                        title="${lockTitle}"
                    >${lockIcon}</button>
                    <button 
                        data-action="click->${this.controllerName}#clearColor"
                        data-color-name="${color.name}"
                        title="Effacer cette couleur de toutes les cellules"
                        ${color.locked ? 'style="display: none;"' : ''}
                    >🧹</button>
                </div>
            </div>
        `;
        }).join('');
    }
    selectColor(event) {
        event.preventDefault();
        if (event.target.tagName === 'BUTTON') {
            return;
        }
        if (this.clickTimeout !== null) {
            window.clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
            return;
        }
        // On attend un peu pour voir si c'est un double-clic
        this.clickTimeout = window.setTimeout(() => {
            this.clickTimeout = null;
            const element = event.target;
            const closest = element.closest('[data-color-name]');
            const colorName = element.dataset.colorName || (closest === null || closest === void 0 ? void 0 : closest.dataset.colorName);
            if (!colorName)
                return;
            this.activeColor = colorName;
            this.updateColorsList();
        }, 200);
    }
    handleMouseDown(event) {
        event.preventDefault();
        const cell = event.currentTarget;
        const hand = cell.dataset.hand;
        if (!hand)
            return;
        this.isMouseDown = true;
        if (this.forceFillTarget.checked) {
            this.handleForceFill(cell);
        }
        else {
            if (!this.checkFrequencyLimit(event)) {
                return;
            }
            const currentFrequencies = this.selectedHands.get(hand) || [];
            const selectedColor = this.activeColor;
            const newFrequency = parseFloat(this.sliderTarget.value);
            const existingColorIndex = currentFrequencies.findIndex(f => f.name === selectedColor);
            this.initialSelectionState = existingColorIndex === -1 ||
                currentFrequencies[existingColorIndex].frequency !== newFrequency;
            this.updateHandState(cell);
        }
    }
    checkFrequencyLimit(event) {
        const cell = event.currentTarget;
        const hand = cell.dataset.hand;
        const newFrequency = parseFloat(this.sliderTarget.value);
        const totalExistingFrequency = RangeImportService.getTotalFrequency(this.selectedHands, hand, this.activeColor);
        if (totalExistingFrequency + newFrequency > 100) {
            NotificationService.showNotification({
                type: 'error',
                message: 'La fréquence totale ne peut pas dépasser 100% !',
            });
            this.isMouseDown = false;
            return false;
        }
        return true;
    }
    updateHandState(cell) {
        const hand = cell.dataset.hand;
        if (!hand)
            return;
        const selectedColor = this.activeColor;
        const colorData = this.colors.find(c => c.name === selectedColor);
        if (!colorData)
            return;
        // Vérifier si la couleur est verrouillée
        if (colorData.locked) {
            NotificationService.showNotification({
                type: 'error',
                message: 'Cette range est verrouillée. Déverrouillez-la avant de la modifier.',
            });
            return;
        }
        const newFrequency = parseFloat(this.sliderTarget.value);
        let frequencies = this.selectedHands.get(hand) || [];
        if (this.initialSelectionState) {
            const existingColorIndex = frequencies.findIndex(f => f.name === selectedColor);
            if (existingColorIndex !== -1) {
                frequencies[existingColorIndex].frequency = newFrequency;
            }
            else {
                frequencies.push({
                    name: selectedColor,
                    color: colorData.color,
                    frequency: newFrequency,
                    locked: colorData.locked
                });
            }
            this.selectedHands.set(hand, frequencies);
        }
        else {
            frequencies = frequencies.filter(f => f.name !== selectedColor);
            if (frequencies.length === 0) {
                this.selectedHands.delete(hand);
            }
            else {
                this.selectedHands.set(hand, frequencies);
            }
        }
        this.updateDisplay();
    }
    updateFrequency() {
        const value = parseFloat(this.sliderTarget.value);
        this.sliderValueTarget.textContent = `${value}%`;
        this.updateDisplay();
    }
    updateRangeStats() {
        // Calculer les statistiques de la range
        const stats = RangeCalculatorService.calculateRangePercentage(this.selectedHands);
        // Mettre à jour l'affichage des stats globales
        this.rangeStatsTarget.textContent = `${stats.total.toFixed(2)}%`;
        // Mettre à jour l'affichage de la liste des couleurs (pour inclure les pourcentages)
        this.updateColorsList();
    }
    setFrequency(event) {
        event.preventDefault();
        const button = event.currentTarget;
        const value = parseInt(button.dataset.value || '0', 10);
        // Mettre à jour la valeur du slider
        this.sliderTarget.value = value.toString();
        // Mettre à jour l'affichage de la valeur
        this.sliderValueTarget.textContent = `${value}%`;
        // Mettre à jour l'affichage des cellules si nécessaire
        this.updateDisplay();
    }
    updateDisplay() {
        this.cellTargets.forEach((cell, index) => {
            const hand = cell.dataset.hand;
            if (!hand)
                return;
            const frequencies = this.selectedHands.get(hand) || [];
            const fill = this.fillTargets[index];
            const handText = cell.querySelector('.range-selector__hand-text');
            if (frequencies.length > 0) {
                cell.classList.add('selected');
                const tooltipContent = frequencies
                    .map(f => `${f.name}: ${f.frequency}%`)
                    .join('\n');
                cell.setAttribute('title', tooltipContent);
                // Trier les fréquences par ordre décroissant pour un meilleur affichage
                const sortedFrequencies = [...frequencies].sort((a, b) => b.frequency - a.frequency);
                handText.style.color = '#000000';
                // Création des barres de couleur
                let currentWidth = 0;
                fill.innerHTML = sortedFrequencies.map(f => {
                    const width = f.frequency;
                    const start = currentWidth;
                    currentWidth += width;
                    return `<div 
                        class="range-selector__fill-segment" 
                        style="
                            background-color: ${f.color}; 
                            left: ${start}%;
                            width: ${width}%;
                            z-index: ${Math.floor(f.frequency)}"
                    ></div>`;
                }).join('');
            }
            else {
                cell.classList.remove('selected');
                cell.removeAttribute('title');
                fill.innerHTML = '';
                handText.style.color = '';
            }
        });
        this.updateRangeStats();
    }
    importRange() {
        const result = RangeImportService.importRange(this.selectedHands, this.activeColor, this.colors);
        if (result) {
            this.updateDisplay();
        }
    }
    exportRange() {
        return __awaiter(this, void 0, void 0, function* () {
            yield RangeExportService.exportRange(this.selectedHands, this.activeColor);
        });
    }
    startEditing(event) {
        event.preventDefault();
        event.stopPropagation();
        // On annule le timeout du clic simple s'il existe
        if (this.clickTimeout !== null) {
            window.clearTimeout(this.clickTimeout);
            this.clickTimeout = null;
        }
        const element = event.target;
        const colorName = element.dataset.colorName;
        if (!colorName)
            return;
        this.showEditInput(element, colorName);
    }
    editColor(event) {
        var _a;
        const button = event.currentTarget;
        const colorName = button.dataset.colorName;
        if (!colorName)
            return;
        const span = (_a = button.closest('.color-item')) === null || _a === void 0 ? void 0 : _a.querySelector('span');
        if (!span)
            return;
        this.showEditInput(span, colorName);
    }
    showEditInput(span, oldName) {
        var _a;
        const input = document.createElement('input');
        input.type = 'text';
        input.value = oldName;
        input.className = 'color-item__edit-input';
        const handleRename = () => {
            const newName = input.value.trim();
            if (newName && newName !== oldName) {
                // Mettre à jour le nom dans la liste des couleurs
                const colorIndex = this.colors.findIndex(c => c.name === oldName);
                if (colorIndex !== -1) {
                    this.colors[colorIndex].name = newName;
                }
                // Mettre à jour le nom dans les mains sélectionnées
                this.selectedHands.forEach((frequencies, hand) => {
                    frequencies.forEach(f => {
                        if (f.name === oldName) {
                            f.name = newName;
                        }
                    });
                });
                // Si c'était la couleur active, mettre à jour l'activeColor
                if (this.activeColor === oldName) {
                    this.activeColor = newName;
                }
                this.updateColorsList();
                this.updateDisplay();
            }
            // Restaurer le span
            span.style.display = '';
            input.remove();
        };
        input.addEventListener('blur', handleRename);
        input.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                handleRename();
            }
            else if (e.key === 'Escape') {
                span.style.display = '';
                input.remove();
            }
        });
        span.style.display = 'none';
        (_a = span.parentNode) === null || _a === void 0 ? void 0 : _a.insertBefore(input, span);
        input.focus();
        input.select();
    }
    clearAllSelections() {
        // Filtrer les mains sélectionnées pour ne garder que celles qui contiennent des couleurs verrouillées
        const lockedColors = this.colors.filter(c => c.locked).map(c => c.name);
        if (lockedColors.length === 0) {
            // Si aucune couleur n'est verrouillée, on peut tout effacer
            this.selectedHands.clear();
            this.rangeNameTarget.value = '';
        }
        else {
            // Sinon, on parcourt toutes les mains et on garde seulement les fréquences des couleurs verrouillées
            this.selectedHands.forEach((frequencies, hand) => {
                const lockedFrequencies = frequencies.filter(f => lockedColors.includes(f.name));
                if (lockedFrequencies.length === 0) {
                    // Si la main n'a plus de fréquences après filtrage, on la supprime
                    this.selectedHands.delete(hand);
                }
                else {
                    // Sinon on met à jour avec seulement les fréquences verrouillées
                    this.selectedHands.set(hand, lockedFrequencies);
                }
            });
        }
        this.updateDisplay();
    }
    handleForceFill(cell) {
        const hand = cell.dataset.hand;
        if (!hand)
            return;
        const selectedColor = this.activeColor;
        const colorData = this.colors.find(c => c.name === selectedColor);
        if (!colorData)
            return;
        // Vérifier si la couleur est verrouillée
        if (colorData.locked) {
            NotificationService.showNotification({
                type: 'error',
                message: 'Cette range est verrouillée. Déverrouillez-la avant de la modifier.',
            });
            return;
        }
        // Récupérer les fréquences existantes pour cette main
        let frequencies = this.selectedHands.get(hand) || [];
        // Calculer la fréquence totale actuelle sans la couleur active
        const totalExistingFrequencyWithoutActive = RangeImportService.getTotalFrequency(this.selectedHands, hand, this.activeColor);
        // Si la fréquence totale est déjà à 100% sans la couleur active, ne rien faire
        if (totalExistingFrequencyWithoutActive >= 100) {
            return;
        }
        // Calculer la fréquence manquante pour atteindre 100%
        const remainingFrequency = 100 - totalExistingFrequencyWithoutActive;
        // Chercher si la couleur active existe déjà pour cette main
        const existingColorIndex = frequencies.findIndex(f => f.name === selectedColor);
        if (existingColorIndex !== -1) {
            // Mettre à jour la fréquence existante
            frequencies[existingColorIndex].frequency = remainingFrequency;
        }
        else {
            // Ajouter la nouvelle fréquence
            frequencies.push({
                name: selectedColor,
                color: colorData.color,
                frequency: remainingFrequency,
                locked: colorData.locked
            });
        }
        // Mettre à jour les fréquences pour cette main
        this.selectedHands.set(hand, frequencies);
        // Mettre à jour l'affichage
        this.updateDisplay();
    }
    toggleLock(event) {
        event.preventDefault();
        event.stopPropagation();
        const button = event.currentTarget;
        const colorName = button.dataset.colorName;
        if (!colorName)
            return;
        // Trouver la couleur dans la liste des couleurs
        const colorIndex = this.colors.findIndex(c => c.name === colorName);
        if (colorIndex === -1)
            return;
        // Inverser l'état de verrouillage
        const newLockedState = !this.colors[colorIndex].locked;
        this.colors[colorIndex].locked = newLockedState;
        // Mettre à jour toutes les mains contenant cette couleur pour propager l'état de verrouillage
        this.selectedHands.forEach((frequencies, hand) => {
            frequencies.forEach(freq => {
                if (freq.name === colorName) {
                    freq.locked = newLockedState;
                }
            });
        });
        // Forcer la mise à jour de l'affichage
        this.updateColorsList();
        this.updateDisplay();
    }
    initializeDualRangeSlider() {
        // Définir les valeurs initiales
        this.rangeMinSliderTarget.value = "0";
        this.rangeMaxSliderTarget.value = "100";
        // Ajouter les écouteurs d'événements
        this.rangeMinSliderTarget.addEventListener('input', this.updateDualRangeSlider.bind(this));
        this.rangeMaxSliderTarget.addEventListener('input', this.updateDualRangeSlider.bind(this));
    }
    // Mise à jour des valeurs du dual range slider
    updateDualRangeSlider() {
        const minVal = parseInt(this.rangeMinSliderTarget.value);
        const maxVal = parseInt(this.rangeMaxSliderTarget.value);
        // Assurer que min ne dépasse pas max
        if (minVal > maxVal) {
            this.rangeMinSliderTarget.value = maxVal.toString();
        }
        this.applyStrengthSelection();
    }
    // Méthode pour appliquer la sélection basée sur la force des mains
    applyStrengthSelection() {
        const minPercentage = parseInt(this.rangeMinSliderTarget.value);
        const maxPercentage = parseInt(this.rangeMaxSliderTarget.value);
        // Récupérer les mains dans l'ordre de force
        const orderedHands = this.getHandsByStrength();
        // Vérifier si orderedHands contient des données valides
        if (orderedHands.length === 0) {
            NotificationService.showNotification({
                type: 'error',
                message: 'Erreur: impossible de récupérer la liste des mains.',
            });
            return;
        }
        // Nettoyer les sélections existantes qui ne sont pas verrouillées
        this.clearNonLockedSelections();
        // Sélectionner les mains dans la plage
        const selectedColor = this.activeColor;
        const colorData = this.colors.find(c => c.name === selectedColor);
        if (!colorData) {
            console.error("Couleur active non trouvée dans la liste des couleurs!");
            NotificationService.showNotification({
                type: 'error',
                message: 'Erreur: couleur active non trouvée.',
            });
            return;
        }
        if (colorData.locked) {
            NotificationService.showNotification({
                type: 'error',
                message: 'Cette range est verrouillée. Déverrouillez-la avant de la modifier.',
            });
            return;
        }
        // Récupérer la fréquence choisie
        const frequency = parseFloat(this.sliderTarget.value);
        // Créer un Set pour les mains qui ont déjà des ranges verrouillées
        const handsWithLockedColors = new Set();
        // Identifier les mains qui ont déjà des couleurs verrouillées
        this.selectedHands.forEach((frequencies, hand) => {
            if (frequencies.some(f => f.locked)) {
                handsWithLockedColors.add(hand);
            }
        });
        // Définir les poids de chaque type de main
        const weights = {
            pair: 0.45, // paires (AAp, KKp, etc.)
            suited: 0.30, // mains assorties (AKs, QTs, etc.)
            offsuit: 0.90 // mains non-assorties (AKo, QTo, etc.)
        };
        // Calculer le poids cumulatif pour chaque main
        let cumulativeWeight = 0;
        const handWeights = orderedHands.map(hand => {
            const isPair = hand.endsWith('p');
            const isSuited = hand.endsWith('s');
            const isOffsuit = hand.endsWith('o');
            let weight = 0;
            if (isPair)
                weight = weights.pair;
            else if (isSuited)
                weight = weights.suited;
            else if (isOffsuit)
                weight = weights.offsuit;
            cumulativeWeight += weight;
            return {
                hand,
                weight,
                cumulativePercent: cumulativeWeight
            };
        });
        // Normaliser les pourcentages cumulatifs à 100%
        const totalWeight = cumulativeWeight;
        handWeights.forEach(hw => {
            hw.cumulativePercent = (hw.cumulativePercent / totalWeight) * 100;
        });
        // Sélectionner les mains basées sur les pourcentages min et max
        for (let i = 0; i < handWeights.length; i++) {
            const handData = handWeights[i];
            // Vérifier si cette main est dans la plage de pourcentage
            if (handData.cumulativePercent < minPercentage) {
                continue; // Passer à la main suivante si en dessous du min
            }
            if (handData.cumulativePercent > maxPercentage) {
                break; // Arrêter si au-dessus du max
            }
            const hand = handData.hand;
            // Vérifier si cette main a déjà des couleurs verrouillées
            if (handsWithLockedColors.has(hand)) {
                continue; // Ne pas modifier les mains avec des couleurs verrouillées
            }
            // Ajouter la couleur active à cette main
            const frequencies = this.selectedHands.get(hand) || [];
            const existingColorIndex = frequencies.findIndex(f => f.name === selectedColor);
            if (existingColorIndex !== -1) {
                frequencies[existingColorIndex].frequency = frequency;
            }
            else {
                frequencies.push({
                    name: selectedColor,
                    color: colorData.color,
                    frequency: frequency,
                    locked: colorData.locked
                });
            }
            this.selectedHands.set(hand, frequencies);
        }
        // Mise à jour de l'affichage
        this.updateDisplay();
        // Mise à jour des valeurs affichées pour les curseurs
        // Utiliser le pourcentage réel calculé par RangeCalculatorService
        const stats = RangeCalculatorService.calculateRangePercentage(this.selectedHands);
    }
    // Méthode pour nettoyer les sélections non verrouillées
    clearNonLockedSelections() {
        const lockedColors = this.colors.filter(c => c.locked).map(c => c.name);
        this.selectedHands.forEach((frequencies, hand) => {
            const nonLockedFrequencies = frequencies.filter(f => !lockedColors.includes(f.name));
            const lockedFrequencies = frequencies.filter(f => lockedColors.includes(f.name));
            if (nonLockedFrequencies.length > 0) {
                if (lockedFrequencies.length > 0) {
                    // Garder seulement les fréquences verrouillées
                    this.selectedHands.set(hand, lockedFrequencies);
                }
                else {
                    // Supprimer la main si elle n'a pas de fréquences verrouillées
                    this.selectedHands.delete(hand);
                }
            }
        });
    }
    // Méthode pour obtenir les mains dans l'ordre de force décroissant
    getHandsByStrength() {
        return [
            "AA", "KK", "QQ", "JJ", "TT", "AKs", "AKo", "AQs", "99", "AJs", "AQo", "KQs", "88",
            "ATs", "AJo", "KJs", "KQo", "77", "A9s", "KTs", "QJs", "ATo", "A8s", "QTs", "JTs",
            "A7s", "A5s", "66", "KJo", "A6s", "A4s", "K9s", "Q9s", "A3s", "T9s", "QJo", "J9s",
            "A2s", "KTo", "55", "A9o", "K8s", "K7s", "QTo", "JTo", "K6s", "Q8s", "T8s", "A8o",
            "44", "J8s", "98s", "A5o", "A7o", "K5s", "33", "K4s", "K9o", "K3s", "Q7s", "Q6s",
            "J7s", "A4o", "T7s", "A6o", "K2s", "Q9o", "T9o", "22", "Q5s", "97s", "A3o", "J9o",
            "87s", "Q4s", "Q3s", "A2o", "K8o", "Q2s", "76s", "J6s", "J5s", "T6s", "96s", "86s",
            "65s", "K7o", "54s", "J4s", "Q8o", "J3s", "T8o", "J8o", "K6o", "75s", "J2s", "98o",
            "K5o", "T5s", "T4s", "64s", "53s", "T3s", "95s", "85s", "K4o", "T2s", "43s", "Q7o",
            "74s", "K3o", "Q6o", "T7o", "J7o", "97o", "63s", "87o", "K2o", "52s", "Q5o", "93s",
            "84s", "94s", "92s", "42s", "Q4o", "73s", "32s", "76o", "Q3o", "65o", "54o", "86o",
            "T6o", "Q2o", "J6o", "96o", "62s", "J5o", "83s", "82s", "J4o", "J3o", "75o", "72s",
            "64o", "J2o", "53o", "85o", "T5o", "95o", "T4o", "43o", "T3o", "T2o", "74o", "63o",
            "52o", "42o", "84o", "93o", "94o", "92o", "32o", "73o", "62o", "82o", "83o", "72o"
        ];
    }
    /**
     * Sauvegarde l'ensemble des ranges dans un groupe
     */
    saveRangeGroup() {
        return __awaiter(this, void 0, void 0, function* () {
            const groupName = this.rangeNameTarget.value.trim();
            // Vérifier que le nom n'est pas vide
            if (!groupName) {
                NotificationService.showNotification({
                    type: 'error',
                    message: 'Veuillez saisir un nom pour le groupe de ranges',
                });
                return;
            }
            // Vérifier que la range n'est pas vide
            if (this.selectedHands.size === 0) {
                NotificationService.showNotification({
                    type: 'error',
                    message: 'Impossible de sauvegarder un groupe de ranges vide',
                });
                return;
            }
            try {
                const result = yield RangeGroupService.saveRangeGroup(this.selectedHands, this.colors, groupName);
                if (result.success) {
                    NotificationService.showNotification({
                        type: 'success',
                        message: result.message,
                    });
                }
                else {
                    NotificationService.showNotification({
                        type: 'error',
                        message: result.message,
                    });
                }
            }
            catch (error) {
                console.error('Erreur lors de la sauvegarde du groupe de ranges:', error);
                NotificationService.showNotification({
                    type: 'error',
                    message: `Erreur lors de la sauvegarde: ${error instanceof Error ? error.message : 'Erreur inconnue'}`,
                });
            }
        });
    }
    /**
     * Charge un groupe de ranges depuis la base de données
     */
    loadRangeGroup(event) {
        return __awaiter(this, void 0, void 0, function* () {
            let groupId = null;
            // Si l'événement est fourni, essayer de récupérer l'ID depuis le bouton
            if (event) {
                const button = event.currentTarget;
                groupId = button.dataset.groupId ? parseInt(button.dataset.groupId, 10) : null;
            }
            // Si aucun ID n'est fourni et qu'on n'a pas déjà un ID de groupe, afficher la liste des groupes
            if (!groupId) {
                yield this.showRangeGroupsList();
                return;
            }
            // Utiliser l'ID fourni ou l'ID actuel
            const idToLoad = groupId;
            if (!idToLoad)
                return;
            yield this.loadRangeGroupById(idToLoad);
            if (this.selectRangeModal) {
                ModalService.closeModal(this.selectRangeModal);
            }
        });
    }
    /**
     * Affiche la liste des groupes de ranges sauvegardés
     */
    showRangeGroupsList() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const result = yield RangeGroupService.getRangeGroupsList();
                if (result.success && result.data) {
                    if (result.data.length === 0) {
                        NotificationService.showNotification({
                            type: 'info',
                            message: 'Aucun groupe de ranges sauvegardé',
                        });
                        return;
                    }
                    const optionsHTML = result.data.map(group => `<option value="${group.id}">${group.name}</option>`).join('');
                    // Ajoutez des styles intégrés
                    // Store the modal reference
                    this.selectRangeModal = ModalService.showModal({
                        title: 'Sélectionner une range',
                        content: `
                    <style>
                        .range-group-select-container {
                            margin: 20px 0;
                        }
                        .range-group-select {
                            width: 100%;
                            padding: 8px;
                            border-radius: 4px;
                            border: 1px solid #ccc;
                            margin-bottom: 15px;
                        }
                        .range-group-load-btn {
                            background-color: #4a90e2;
                            color: white;
                            border: none;
                            border-radius: 4px;
                            padding: 8px 15px;
                            cursor: pointer;
                            transition: background-color 0.2s;
                            width: 100%;
                        }
                        .range-group-load-btn:hover {
                            background-color: #357bd8;
                        }
                    </style>
                    <div class="range-group-select-container">
                        <select id="range-group-select" class="range-group-select">
                            ${optionsHTML}
                        </select>
                        <button id="load-range-group-btn" class="range-group-load-btn">
                            Charger
                        </button>
                    </div>
                    `,
                        closable: true,
                        persistent: false,
                    });
                    // Add event listener to the load button
                    const loadButton = document.getElementById('load-range-group-btn');
                    const selectElement = document.getElementById('range-group-select');
                    if (loadButton && selectElement) {
                        loadButton.addEventListener('click', () => {
                            const selectedValue = selectElement.value;
                            if (selectedValue) {
                                const groupId = parseInt(selectedValue, 10);
                                this.loadRangeGroup({ currentTarget: { dataset: { groupId } } });
                            }
                        });
                    }
                }
                else {
                    NotificationService.showNotification({
                        type: 'error',
                        message: result.message || 'Erreur lors de la récupération des groupes de ranges',
                    });
                }
            }
            catch (error) {
                console.error('Erreur lors de la récupération de la liste des groupes:', error);
                NotificationService.showNotification({
                    type: 'error',
                    message: `Erreur: ${error instanceof Error ? error.message : 'Erreur inconnue'}`,
                });
            }
        });
    }
}
RangeSelectorController.targets = [
    'cell',
    'fill',
    'slider',
    'sliderValue',
    'colorsContainer',
    'forceFill',
    'rangeStats',
    'rangeMinSlider',
    'rangeMaxSlider',
    'rangeName',
];
RangeSelectorController.values = {
    rangeGroupId: { type: Number, default: null }
};
export default RangeSelectorController;
