var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from "@app/core/http/HttpService";
import routeService from "@app/core/http/RouteService";
export class RangeGroupService {
    /**
     * Sauvegarde un groupe de ranges en base de données
     *
     * @param selectedHands Map des mains sélectionnées avec leurs fréquences par couleur
     * @param colors Définition des couleurs utilisées
     * @param groupName Nom du groupe de ranges
     */
    static saveRangeGroup(selectedHands, colors, groupName) {
        return __awaiter(this, void 0, void 0, function* () {
            // Organiser les données par couleur pour notre structure d'entité
            const rangesByColor = {};
            // Initialiser avec toutes les couleurs
            colors.forEach(color => {
                rangesByColor[color.name] = {};
            });
            // Remplir avec les données des mains
            selectedHands.forEach((frequencies, hand) => {
                frequencies.forEach(freq => {
                    if (!rangesByColor[freq.name]) {
                        rangesByColor[freq.name] = {};
                    }
                    rangesByColor[freq.name][hand] = freq.frequency;
                });
            });
            // Préparer les données pour l'API
            const rangesData = Object.entries(rangesByColor).map(([colorName, hands]) => {
                const colorObj = colors.find(c => c.name === colorName);
                return {
                    name: colorName,
                    color: (colorObj === null || colorObj === void 0 ? void 0 : colorObj.color) || '#000000',
                    locked: (colorObj === null || colorObj === void 0 ? void 0 : colorObj.locked) || false,
                    value: JSON.stringify(hands)
                };
            });
            // S'assurer que les routes sont chargées
            yield routeService.loadRoutes();
            const url = routeService.getRoute('api_range_groups_save');
            const { result, error } = yield HttpService.request({
                url,
                method: 'POST',
                payload: {
                    name: groupName,
                    ranges: rangesData
                },
                showLoader: true
            });
            if (error || !result) {
                return {
                    success: false,
                    message: (error === null || error === void 0 ? void 0 : error.message) || 'Erreur lors de la sauvegarde des ranges'
                };
            }
            return {
                success: true,
                message: result.message || 'Ranges sauvegardées avec succès',
                id: result.id
            };
        });
    }
    /**
     * Récupère un groupe de ranges depuis la base de données
     */
    static getRangeGroup(id) {
        return __awaiter(this, void 0, void 0, function* () {
            // S'assurer que les routes sont chargées
            yield routeService.loadRoutes();
            const url = routeService.getRoute('api_range_groups_get', { id });
            const { result, error } = yield HttpService.request({
                url,
                method: 'GET',
                showLoader: true
            });
            if (error || !result) {
                return {
                    success: false,
                    message: (error === null || error === void 0 ? void 0 : error.message) || 'Erreur lors de la récupération du groupe de ranges'
                };
            }
            try {
                // Traiter les données reçues pour les reconvertir en notre format interne
                const hands = new Map();
                const colors = [];
                // Extraire les informations des couleurs
                result.ranges.forEach(range => {
                    colors.push({
                        name: range.name,
                        color: range.color,
                        frequency: 0, // Valeur par défaut
                        locked: range.locked
                    });
                    // Convertir la valeur JSON en objet
                    const rangeData = JSON.parse(range.value);
                    // Pour chaque main dans cette range
                    Object.entries(rangeData).forEach(([hand, frequency]) => {
                        const freq = Number(frequency);
                        if (freq > 0) {
                            // Récupérer ou créer l'entrée pour cette main
                            const existing = hands.get(hand) || [];
                            // Ajouter la fréquence pour cette couleur
                            existing.push({
                                name: range.name,
                                color: range.color,
                                frequency: freq,
                                locked: range.locked
                            });
                            // Mettre à jour la map
                            hands.set(hand, existing);
                        }
                    });
                });
                return {
                    success: true,
                    data: {
                        name: result.name,
                        hands: hands,
                        colors: colors
                    }
                };
            }
            catch (error) {
                console.error('Erreur lors du traitement des données:', error);
                return {
                    success: false,
                    message: `Erreur lors du traitement des données: ${error instanceof Error ? error.message : 'Erreur inconnue'}`
                };
            }
        });
    }
    /**
     * Récupère la liste des groupes de ranges
     */
    static getRangeGroupsList() {
        return __awaiter(this, void 0, void 0, function* () {
            // S'assurer que les routes sont chargées
            yield routeService.loadRoutes();
            const url = routeService.getRoute('api_range_groups_list');
            const { result, error } = yield HttpService.request({
                url,
                method: 'GET',
                showLoader: true
            });
            if (error || !result) {
                return {
                    success: false,
                    message: (error === null || error === void 0 ? void 0 : error.message) || 'Erreur lors de la récupération de la liste des groupes'
                };
            }
            return {
                success: true,
                data: result.groups
            };
        });
    }
    /**
     * Supprime un groupe de ranges
     */
    static deleteRangeGroup(id) {
        return __awaiter(this, void 0, void 0, function* () {
            // S'assurer que les routes sont chargées
            yield routeService.loadRoutes();
            const url = routeService.getRoute('api_range_groups_delete', { id });
            const { result, error } = yield HttpService.request({
                url,
                method: 'DELETE',
                showLoader: true
            });
            if (error || !result) {
                return {
                    success: false,
                    message: (error === null || error === void 0 ? void 0 : error.message) || 'Erreur lors de la suppression du groupe'
                };
            }
            return {
                success: true,
                message: result.message || 'Groupe supprimé avec succès'
            };
        });
    }
}
