import { NotificationService } from "@app/core/notification/NotificationService";
import { RangeFormatEnum } from "@app/range/RangeFormat.enum";
export class RangeImportService {
    static getTotalFrequency(selectedHands, hand, excludeColorName) {
        const frequencies = selectedHands.get(hand) || [];
        return frequencies
            .filter(f => f.name !== excludeColorName)
            .reduce((sum, f) => sum + f.frequency, 0);
    }
    static importRange(selectedHands, activeColor, colors) {
        // Vérifier si la couleur active est verrouillée
        const colorData = colors.find(c => c.name === activeColor);
        if (colorData && colorData.locked) {
            NotificationService.showNotification({
                type: 'error',
                message: 'Cette range est verrouillée. Déverrouillez-la avant de l\'importer ou choisissez une autre range.',
            });
            return false;
        }
        const rangeText = prompt('Collez votre range :');
        if (!rangeText)
            return false;
        try {
            const format = this.detectRangeFormat(rangeText);
            let handsWithFrequencies;
            if (format === RangeFormatEnum.GTOWizard) {
                handsWithFrequencies = this.parseGTOWizardRange(rangeText);
            }
            else if (format === RangeFormatEnum.FlopZilla) {
                handsWithFrequencies = this.parseFlopZillaRange(rangeText);
            }
            else if (format === RangeFormatEnum.Equilab) {
                handsWithFrequencies = this.parseEquilabRange(rangeText);
            }
            else {
                // Format PioSolver original
                handsWithFrequencies = new Map();
                const parts = rangeText.split(',');
                parts.forEach(part => {
                    const [hand, frequencyStr] = part.split(':');
                    if (!hand)
                        return;
                    // Si pas de fréquence spécifiée, c'est 100%
                    const frequency = frequencyStr ? parseFloat(frequencyStr) * 100 : 100;
                    handsWithFrequencies.set(hand, frequency);
                });
            }
            // Récupérer la liste des couleurs verrouillées
            const lockedColors = colors.filter(c => c.locked).map(c => c.name);
            // Nettoyer toutes les mains existantes en ne gardant que les couleurs verrouillées
            selectedHands.forEach((frequencies, hand) => {
                // Filtrer pour ne garder que les fréquences des couleurs verrouillées
                const lockedFrequencies = frequencies.filter(f => lockedColors.includes(f.name));
                if (lockedFrequencies.length === 0) {
                    // Si la main n'a plus de fréquences après filtrage, on la supprime
                    selectedHands.delete(hand);
                }
                else {
                    // Sinon on met à jour avec seulement les fréquences verrouillées
                    selectedHands.set(hand, lockedFrequencies);
                }
            });
            // Vérification des mains problématiques
            const problematicHands = [];
            handsWithFrequencies.forEach((frequency, hand) => {
                const existingTotal = RangeImportService.getTotalFrequency(selectedHands, hand, activeColor);
                if (existingTotal + frequency > 100) {
                    problematicHands.push(hand);
                }
            });
            if (problematicHands.length > 0) {
                alert(`Import impossible. Les mains suivantes dépasseraient 100% : ${problematicHands.join(', ')}`);
                return false;
            }
            // Importer les mains
            const colorData = colors.find(c => c.name === activeColor);
            if (!colorData)
                return false;
            handsWithFrequencies.forEach((frequency, hand) => {
                // Récupérer les fréquences existantes pour cette main
                const handFrequencies = selectedHands.get(hand) || [];
                // Chercher si la couleur active existe déjà pour cette main
                const existingColorIndex = handFrequencies.findIndex(f => f.name === activeColor);
                if (existingColorIndex !== -1) {
                    // Mettre à jour la fréquence existante
                    handFrequencies[existingColorIndex].frequency = frequency;
                }
                else {
                    // Ajouter la nouvelle fréquence
                    handFrequencies.push({
                        name: activeColor,
                        color: colorData.color,
                        frequency: frequency,
                        locked: colorData.locked
                    });
                }
                // Mettre à jour ou ajouter les fréquences pour cette main
                selectedHands.set(hand, handFrequencies);
            });
            // Informer l'utilisateur du format détecté
            NotificationService.showNotification({
                type: 'success',
                message: `Range importée au format ${format} avec succès ! Les ranges non verrouillées ont été supprimées.`,
            });
            return true;
        }
        catch (error) {
            alert('Format de range invalide. Formats supportés: PioSolver, GTO Wizard, FlopZilla et Equilab.');
            return false;
        }
    }
    static detectRangeFormat(rangeText) {
        // Format Equilab: contient des notations comme "44+,A5s+,KTs+,QJs,A8o+,KJo+"
        if (/[AKQJT98765432]{2}\+/.test(rangeText) || /[AKQJT98765432][AKQJT98765432][so]\+/.test(rangeText)) {
            return RangeFormatEnum.Equilab;
        }
        // Format GTO Wizard: contient des combinaisons spécifiques de cartes comme "AhKc: 0.75"
        if (rangeText.includes(':') && /[AKQJT98765432][cdhs][AKQJT98765432][cdhs]/.test(rangeText)) {
            return RangeFormatEnum.GTOWizard;
        }
        // FlopZilla format contient généralement [45] ou similaire
        if (rangeText.includes('[') && rangeText.includes(']')) {
            return RangeFormatEnum.FlopZilla;
        }
        // Format standard PioSolver
        return RangeFormatEnum.PioSolver;
    }
    /**
     * Parse une range au format GTO Wizard
     * Format: 2d2c: 1,2h2c: 1,2h2d: 1,2s2c: 1,2s2d: 1,2s2h: 1,...
     */
    static parseGTOWizardRange(rangeText) {
        const result = new Map();
        const hands = new Map(); // Pour regrouper par combinaison standard (ex: "AA", "AKs")
        // Diviser par paires de cartes
        const parts = rangeText.split(',').map(part => part.trim());
        for (const part of parts) {
            if (!part)
                continue;
            // Extraire la combinaison et la fréquence
            const [cards, freqStr] = part.split(':').map(s => s.trim());
            if (!cards || !freqStr)
                continue;
            const frequency = parseFloat(freqStr) * 100; // Convertir en pourcentage
            // Ignorer les mains avec fréquence 0
            if (frequency <= 0)
                continue;
            // Convertir du format spécifique (ex: "AhKs") au format standard (ex: "AKs")
            const standardHand = this.convertToStandardNotation(cards);
            if (!standardHand)
                continue;
            // Si la main existe déjà, on prend la valeur max (pour gérer les cas où plusieurs combinaisons de même type ont des fréquences différentes)
            const existingFreq = hands.get(standardHand) || 0;
            hands.set(standardHand, Math.max(existingFreq, frequency));
        }
        return hands;
    }
    /**
     * Parse une range FlopZilla
     * Format: AA,TT,77,[45]QQ,99,66[/45],[15]44[/15]
     * Ou format: AA-88,AKs-A2s,[50.0000]65s,54s,ATo[/50.0000]
     */
    static parseFlopZillaRange(rangeText) {
        const result = new Map();
        // Gérer les segments de pourcentage avec regex (sans le flag s qui n'est pas compatible)
        const percentageRegex = /\[([\d.]+)\](.*?)\[\/\1\]/g;
        let match;
        // Traiter d'abord toutes les mains avec une fréquence par défaut (100%)
        let defaultContent = rangeText;
        while ((match = percentageRegex.exec(rangeText)) !== null) {
            // Remplacer chaque segment trouvé par une chaîne vide
            defaultContent = defaultContent.replace(match[0], '');
        }
        // Traiter les mains par défaut (100%)
        if (defaultContent.trim()) {
            const defaultHands = this.expandRangeNotation(defaultContent);
            defaultHands.forEach(hand => {
                result.set(hand, 100);
            });
        }
        // Réinitialiser regex pour un nouveau parcours
        percentageRegex.lastIndex = 0;
        // Traiter les segments avec fréquence spécifique
        while ((match = percentageRegex.exec(rangeText)) !== null) {
            const percentage = parseFloat(match[1]);
            const content = match[2];
            const hands = this.expandRangeNotation(content);
            hands.forEach(hand => {
                result.set(hand, percentage);
            });
        }
        return result;
    }
    /**
     * Parse une range au format Equilab
     * Format: 44+,A5s+,KTs+,QJs,A8o+,KJo+
     */
    static parseEquilabRange(rangeText) {
        const result = new Map();
        const cardOrder = 'AKQJT98765432';
        // Diviser par segments
        const parts = rangeText.split(',').map(part => part.trim());
        for (const part of parts) {
            if (!part)
                continue;
            // Vérifier si c'est une plage "+" (ex: "44+", "A5s+")
            const isRangePlus = part.endsWith('+');
            const basePart = isRangePlus ? part.slice(0, -1) : part;
            // Cas 1: Paires (ex: "44+")
            if (/^([AKQJT98765432])\1$/.test(basePart)) {
                const pairRank = basePart[0];
                const pairRankIndex = cardOrder.indexOf(pairRank);
                if (isRangePlus) {
                    // Toutes les paires à partir de celle-ci (ex: "44+" = 44, 55, 66, ..., AA)
                    for (let i = 0; i <= pairRankIndex; i++) {
                        const card = cardOrder[i];
                        result.set(card + card, 100);
                    }
                }
                else {
                    // Seulement cette paire
                    result.set(basePart, 100);
                }
            }
            // Cas 2: Mains suited ou offsuited (ex: "A5s+", "KJo")
            else if (/^([AKQJT98765432])([AKQJT98765432])([so])$/.test(basePart)) {
                const firstCard = basePart[0];
                const secondCard = basePart[1];
                const suitedness = basePart[2]; // 's' pour suited, 'o' pour offsuited
                const firstCardIndex = cardOrder.indexOf(firstCard);
                const secondCardIndex = cardOrder.indexOf(secondCard);
                if (isRangePlus && firstCardIndex < secondCardIndex) {
                    // Toutes les mains de même type avec la même première carte et une deuxième carte de rang égal ou supérieur
                    // Ex: "A5s+" = A5s, A6s, A7s, ..., AKs
                    for (let i = secondCardIndex; i >= 0; i--) {
                        // Ne pas inclure les paires
                        if (i === firstCardIndex)
                            continue;
                        const card2 = cardOrder[i];
                        result.set(firstCard + card2 + suitedness, 100);
                    }
                }
                else {
                    // Seulement cette main
                    result.set(basePart, 100);
                }
            }
            // Cas 3: Plages entre deux mains (pour des formats plus avancés qui pourraient utiliser "-")
            else if (part.includes('-')) {
                const [start, end] = part.split('-').map(h => h.trim());
                // Traitement de plages personnalisées si nécessaire
                // Cette partie peut être étendue selon les besoins spécifiques
            }
        }
        return result;
    }
    /**
     * Convertit une notation spécifique de cartes (ex: "AhKs") en notation standard (ex: "AKs")
     */
    static convertToStandardNotation(specificCards) {
        // Vérifier le format de la main
        if (!/^[AKQJT98765432][cdhs][AKQJT98765432][cdhs]$/.test(specificCards)) {
            return null;
        }
        // Extraire les valeurs et couleurs
        const card1Value = specificCards[0];
        const card1Suit = specificCards[1];
        const card2Value = specificCards[2];
        const card2Suit = specificCards[3];
        // Cas d'une paire (ex: "AhAs" -> "AA")
        if (card1Value === card2Value) {
            return card1Value + card2Value;
        }
        // Normaliser l'ordre pour avoir la carte la plus forte en premier
        const cardOrder = 'AKQJT98765432';
        const card1Rank = cardOrder.indexOf(card1Value);
        const card2Rank = cardOrder.indexOf(card2Value);
        let firstCard, secondCard, suited;
        if (card1Rank < card2Rank) {
            // La première carte est plus forte
            firstCard = card1Value;
            secondCard = card2Value;
            suited = card1Suit === card2Suit ? 's' : 'o';
        }
        else {
            // La deuxième carte est plus forte
            firstCard = card2Value;
            secondCard = card1Value;
            suited = card1Suit === card2Suit ? 's' : 'o';
        }
        return firstCard + secondCard + suited;
    }
    /**
     * Étend la notation de range en mains individuelles
     * Par exemple: "AKs-A6s" devient ["AKs", "AQs", "AJs", "ATs", "A9s", "A8s", "A7s", "A6s"]
     */
    static expandRangeNotation(notation) {
        const hands = [];
        const parts = notation.split(',');
        const cardOrder = 'AKQJT98765432';
        parts.forEach(part => {
            part = part.trim();
            if (!part)
                return;
            // Cas d'une plage: "AKs-A6s" ou "AA-88"
            if (part.includes('-')) {
                const [start, end] = part.split('-').map(h => h.trim());
                // Vérifier si les deux mains ont le même format (suited ou offsuited)
                const startSuited = start.endsWith('s');
                const startOffsuited = start.endsWith('o');
                const endSuited = end.endsWith('s');
                const endOffsuited = end.endsWith('o');
                // Extraire les valeurs des cartes sans le suffixe
                const cleanStart = start.replace(/[so]$/, '');
                const cleanEnd = end.replace(/[so]$/, '');
                // Cas des paires (ex: "AA-88")
                if (cleanStart.length === 2 && cleanStart[0] === cleanStart[1] &&
                    cleanEnd.length === 2 && cleanEnd[0] === cleanEnd[1]) {
                    const startIdx = cardOrder.indexOf(cleanStart[0]);
                    const endIdx = cardOrder.indexOf(cleanEnd[0]);
                    // Vérifier que les indices sont valides et dans le bon ordre
                    if (startIdx !== -1 && endIdx !== -1 && startIdx <= endIdx) {
                        for (let i = startIdx; i <= endIdx; i++) {
                            const card = cardOrder[i];
                            hands.push(card + card);
                        }
                    }
                }
                // Cas des mains suited/offsuited (ex: "AKs-A2s")
                else if ((startSuited && endSuited) || (startOffsuited && endOffsuited)) {
                    const suffix = startSuited ? 's' : 'o';
                    // Si la première carte est la même (ex: "AKs-A2s")
                    if (cleanStart[0] === cleanEnd[0]) {
                        const firstCard = cleanStart[0];
                        const startSecondIdx = cardOrder.indexOf(cleanStart[1]);
                        const endSecondIdx = cardOrder.indexOf(cleanEnd[1]);
                        // Vérifier que les indices sont valides et dans le bon ordre
                        if (startSecondIdx !== -1 && endSecondIdx !== -1) {
                            // Attention: ici l'ordre est inversé car AKs-A2s va de la carte la plus haute à la plus basse
                            const minIdx = Math.min(startSecondIdx, endSecondIdx);
                            const maxIdx = Math.max(startSecondIdx, endSecondIdx);
                            for (let i = minIdx; i <= maxIdx; i++) {
                                hands.push(`${firstCard}${cardOrder[i]}${suffix}`);
                            }
                        }
                    }
                    // Autres cas plus complexes (ex: "AKs-KQs")
                    else {
                        // Implémentation pour d'autres cas si nécessaire
                    }
                }
            }
            // Cas d'une main simple comme "AKs" ou "TT"
            else {
                hands.push(part);
            }
        });
        return hands;
    }
}
