var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Controller } from '@hotwired/stimulus';
import { HttpService } from "@app/core/http/HttpService";
import routeService from '@app/core/http/RouteService';
class AdminQuizCrudController extends Controller {
    connect() {
    }
    checkQuiz() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.allTargetsOK()) {
                return;
            }
            let learningThemeIds = this.learningThemesTargets.map(t => +t.value);
            let nbQuestions = +this.nbQuestionsInputTarget.value;
            const { result, error } = yield HttpService.request({
                url: routeService.getRoute('admin_quiz_crud_check_quiz_construction'),
                method: 'POST',
                payload: { learningThemsIds: learningThemeIds, nbQuestions: nbQuestions },
                errorMessage: "Erreur lors de la vérification du quiz"
            });
            if (!error) {
                this.ctnResultsTarget.classList.remove('hidden');
                this.ctnResultsTarget.innerHTML = result;
            }
        });
    }
    allTargetsOK() {
        return this.hasLearningThemesTarget
            && this.hasNbQuestionsInputTarget
            && this.hasCtnResultsTarget;
    }
}
AdminQuizCrudController.targets = [
    'learningThemes',
    'nbQuestionsInput',
    'ctnResults',
];
export default AdminQuizCrudController;
