var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class LoaderService {
    /**
     * Affiche un loader plein écran qui bloque les interactions avec l'UI
     */
    static show(options) {
        // Fusionner les options par défaut avec celles fournies
        const mergedOptions = Object.assign(Object.assign({}, this.defaultOptions), options);
        // Annuler tout timeout existant
        if (this.showTimeout !== null) {
            window.clearTimeout(this.showTimeout);
            this.showTimeout = null;
        }
        // Si un loader est déjà affiché, ne rien faire
        if (this.loaderElement) {
            return;
        }
        // Utiliser un délai pour éviter les flashs de loader pour les opérations rapides
        this.showTimeout = window.setTimeout(() => {
            this.createLoader(mergedOptions);
            this.showTimeout = null;
        }, mergedOptions.delayBeforeShow);
    }
    /**
     * Crée et affiche l'élément loader
     */
    static createLoader(options) {
        // Créer l'élément principal du loader
        this.loaderElement = document.createElement('div');
        this.loaderElement.className = 'global-loader-overlay';
        this.loaderElement.setAttribute('role', 'status');
        this.loaderElement.setAttribute('aria-live', 'polite');
        this.loaderElement.style.position = 'fixed';
        this.loaderElement.style.top = '0';
        this.loaderElement.style.left = '0';
        this.loaderElement.style.width = '100%';
        this.loaderElement.style.height = '100%';
        this.loaderElement.style.display = 'flex';
        this.loaderElement.style.alignItems = 'center';
        this.loaderElement.style.justifyContent = 'center';
        this.loaderElement.style.backgroundColor = options.backgroundColor;
        this.loaderElement.style.zIndex = options.zIndex.toString();
        this.loaderElement.style.opacity = '0';
        this.loaderElement.style.transition = 'opacity 0.2s ease-in-out';
        // Créer le spinner
        const spinner = document.createElement('div');
        spinner.className = 'loader-spinner';
        spinner.style.width = `${options.spinnerSize}px`;
        spinner.style.height = `${options.spinnerSize}px`;
        spinner.style.border = `4px solid ${options.spinnerColor}`;
        spinner.style.borderRadius = '50%';
        spinner.style.borderTop = `4px solid transparent`;
        spinner.style.animation = 'spin 1s linear infinite';
        this.loaderElement.appendChild(spinner);
        // Ajouter une règle CSS pour l'animation du spinner si elle n'existe pas déjà
        if (!document.getElementById('loader-style')) {
            const style = document.createElement('style');
            style.id = 'loader-style';
            style.textContent = `
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
            `;
            document.head.appendChild(style);
        }
        // Ajouter le loader au document
        document.body.appendChild(this.loaderElement);
        // Désactiver l'interaction avec le reste de la page
        this.togglePageInteractivity(false);
        // Animer l'apparition
        requestAnimationFrame(() => {
            if (this.loaderElement) {
                this.loaderElement.style.opacity = '1';
            }
        });
    }
    /**
     * Masque le loader
     */
    static hide() {
        // Annuler tout timeout en attente
        if (this.showTimeout !== null) {
            window.clearTimeout(this.showTimeout);
            this.showTimeout = null;
        }
        // Si aucun loader n'est affiché, ne rien faire
        if (!this.loaderElement) {
            return;
        }
        // Animer la disparition
        this.loaderElement.style.opacity = '0';
        // Supprimer le loader après l'animation
        setTimeout(() => {
            if (this.loaderElement && this.loaderElement.parentElement) {
                this.loaderElement.parentElement.removeChild(this.loaderElement);
                this.loaderElement = null;
            }
            // Réactiver l'interactivité de la page
            this.togglePageInteractivity(true);
        }, 200);
    }
    /**
     * Utilitaire pour exécuter une fonction asynchrone avec le loader
     * automatiquement affiché pendant son exécution
     */
    static withLoader(asyncFn, options) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                this.show(options);
                return yield asyncFn();
            }
            finally {
                this.hide();
            }
        });
    }
    /**
     * Active ou désactive l'interactivité du reste de la page
     */
    static togglePageInteractivity(interactive) {
        document.querySelectorAll('body > *:not(.global-loader-overlay)').forEach(el => {
            if (el instanceof HTMLElement) {
                if (interactive) {
                    el.removeAttribute('inert');
                }
                else {
                    el.setAttribute('inert', '');
                }
            }
        });
    }
}
LoaderService.loaderElement = null;
LoaderService.showTimeout = null;
LoaderService.defaultOptions = {
    spinnerColor: '#ffffff',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 9999,
    delayBeforeShow: 300,
    spinnerSize: 48
};
