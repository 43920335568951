import { Controller } from '@hotwired/stimulus';
export default class AdminQuizzQuestionCrudController extends Controller {
    constructor() {
        super(...arguments);
        this.observer = null;
    }
    connect() {
        this.observeCollection();
    }
    disconnect() {
        var _a;
        (_a = this.observer) === null || _a === void 0 ? void 0 : _a.disconnect();
    }
    observeCollection() {
        this.observer = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node instanceof HTMLElement && node.classList.contains("field-collection-item")) {
                        this.element.dispatchEvent(new CustomEvent("ckeditor-element-added", {
                            bubbles: true,
                            detail: { element: node }
                        }));
                    }
                });
            });
        });
        this.observer.observe(this.element, { childList: true, subtree: true });
    }
}
