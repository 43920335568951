var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Controller } from '@hotwired/stimulus';
import { HttpService } from "@app/core/http/HttpService";
import routeService from "@app/core/http/RouteService";
class default_1 extends Controller {
    connect() {
        this.formTarget.addEventListener('submit', (event) => {
            event.preventDefault();
        });
        this.updateButtonState();
        this.formTarget.addEventListener('input', this.updateButtonState.bind(this));
        this.observeComponentRender();
    }
    updateButtonState() {
        const cbChecked = this.answerInputTargets.some(cb => cb.checked);
        this.validateButtonTarget.disabled = !cbChecked;
    }
    validateAnswers(event) {
        return __awaiter(this, void 0, void 0, function* () {
            event.preventDefault();
            this.answerInputTargets.forEach(input => { input.disabled = true; });
            if (this.hasAnswerDetailsTarget) {
                this.answerDetailsTargets.forEach(detail => { detail.classList.remove('hidden'); });
            }
            this.answerStatusTargets.forEach(status => { status.classList.remove('hidden'); });
            this.validateButtonTarget.classList.add('hidden');
            this.nextButtonTarget.classList.remove('hidden');
            if (this.hasQuestionMoreDetailsTarget) {
                this.questionMoreDetailsTarget.classList.remove('hidden');
            }
            this.computeAnswerStatus();
            yield this.persistAnswer();
        });
    }
    computeAnswerStatus() {
        const isMultiple = this.element.dataset.isMultiple === '1';
        let nbCorrectAnswers = 0;
        let nbRightAnswers = 0;
        if (isMultiple) {
            nbCorrectAnswers = this.answerInputTargets.length;
            nbRightAnswers = this.answerInputTargets.filter(input => {
                var _a, _b;
                return (((_a = input === null || input === void 0 ? void 0 : input.dataset) === null || _a === void 0 ? void 0 : _a.isCorrect) === "1" && input.checked) || (((_b = input === null || input === void 0 ? void 0 : input.dataset) === null || _b === void 0 ? void 0 : _b.isCorrect) !== "1" && !input.checked);
            }).length;
        }
        else {
            nbCorrectAnswers = 1;
            nbRightAnswers = this.answerInputTargets.filter(input => {
                var _a;
                return ((_a = input === null || input === void 0 ? void 0 : input.dataset) === null || _a === void 0 ? void 0 : _a.isCorrect) === "1" && input.checked;
            }).length;
        }
        let resultClass = 'answer-response-error';
        if (nbRightAnswers === nbCorrectAnswers) {
            resultClass = 'answer-response-success';
        }
        else if (nbRightAnswers > 0) {
            resultClass = 'answer-response-warning';
        }
        this.answerOptionsTarget.classList.add(resultClass);
    }
    resetInputs() {
        this.answerInputTargets.forEach(input => {
            input.disabled = false;
            input.checked = false;
            input.removeAttribute('checked');
        });
        this.answerDetailsTargets.forEach(detail => { detail.classList.add('hidden'); });
        this.answerStatusTargets.forEach(status => { status.classList.add('hidden'); });
        if (this.hasQuestionMoreDetailsTarget) {
            this.questionMoreDetailsTarget.classList.add('hidden');
        }
        this.answerOptionsTarget.classList.remove(...['answer-response-error', 'answer-response-success', 'answer-response-warning']);
        this.formTarget.reset();
        this.validateButtonTarget.classList.remove('hidden');
        this.nextButtonTarget.classList.add('hidden');
        this.validateButtonTarget.disabled = true;
    }
    persistAnswer() {
        return __awaiter(this, void 0, void 0, function* () {
            let selectedAnswerIds = this.answerInputTargets
                .filter(input => input.checked)
                .map(input => { var _a; return (_a = input === null || input === void 0 ? void 0 : input.dataset) === null || _a === void 0 ? void 0 : _a.answerId; });
            let generatedLearningQuizId = this.element.dataset.generatedLearningQuizId;
            yield HttpService.request({
                url: routeService.getRoute('app_learning_quiz_answer'),
                method: 'POST',
                payload: { answerIds: selectedAnswerIds, generatedLearningQuizId: generatedLearningQuizId },
                errorMessage: "Erreur lors de la sauvegarde des réponses"
            });
        });
    }
    observeComponentRender() {
        // Vérifie si un observer est déjà en place
        if (this.observer) {
            this.observer.disconnect();
        }
        // Création d'un observer qui détecte les changements du composant
        this.observer = new MutationObserver(() => {
            this.resetInputs();
        });
        // Observer les changements sur l'élément principal du composant
        this.observer.observe(this.element, { childList: true, subtree: true });
    }
    disconnect() {
        if (this.hasFormTarget) {
            this.formTarget.removeEventListener('input', this.updateButtonState.bind(this));
        }
        // Nettoie l'observer lorsque le contrôleur est déconnecté
        if (this.observer) {
            this.observer.disconnect();
        }
    }
}
default_1.targets = [
    'form',
    'answerInput',
    'answerOptions',
    'answerStatus',
    'answerDetails',
    'questionMoreDetails',
    'validateButton',
    'nextButton'
];
export default default_1;
