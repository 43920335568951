var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { NotificationService } from "@app/core/notification/NotificationService";
import { RangeFormatEnum } from "@app/range/RangeFormat.enum";
export default class RangeExportService {
    static exportRange(selectedHands, activeColor) {
        return __awaiter(this, void 0, void 0, function* () {
            const format = prompt('Choisissez un format d\'export :\n1. PioSolver (par défaut)\n2. GTOWizard\n3. FlopZilla\n4. Equilab');
            // Déterminer le format sélectionné
            let formatType = RangeFormatEnum.PioSolver; // Format par défaut
            if (format === '2')
                formatType = RangeFormatEnum.GTOWizard;
            if (format === '3')
                formatType = RangeFormatEnum.FlopZilla;
            if (format === '4')
                formatType = RangeFormatEnum.Equilab;
            // Récupérer toutes les mains qui utilisent la couleur active
            const handsWithActiveColor = Array.from(selectedHands.entries())
                .map(([hand, frequencies]) => {
                var _a;
                const activeFreq = (_a = frequencies.find(f => f.name === activeColor)) === null || _a === void 0 ? void 0 : _a.frequency;
                return activeFreq ? { hand, frequency: activeFreq } : null;
            })
                .filter((item) => item !== null);
            let result = '';
            // Générer le résultat selon le format choisi
            if (formatType === RangeFormatEnum.PioSolver) {
                result = this.exportToPioSolver(handsWithActiveColor);
            }
            else if (formatType === RangeFormatEnum.GTOWizard) {
                result = this.exportToGTOWizard(handsWithActiveColor);
            }
            else if (formatType === RangeFormatEnum.FlopZilla) {
                result = this.exportToFlopZilla(handsWithActiveColor);
            }
            else if (formatType === RangeFormatEnum.Equilab) {
                result = this.exportToEquilab(handsWithActiveColor);
            }
            try {
                // Try using the clipboard API first
                if (navigator.clipboard && navigator.clipboard.writeText) {
                    yield navigator.clipboard.writeText(result);
                    NotificationService.showNotification({
                        type: 'success',
                        message: `Range exportée au format ${formatType} et copiée dans le presse-papier !`,
                    });
                }
                else {
                    // Fallback: Create a temporary textarea element
                    const textarea = document.createElement('textarea');
                    textarea.value = result;
                    textarea.style.position = 'fixed'; // Prevent scrolling to bottom
                    textarea.style.opacity = '0';
                    document.body.appendChild(textarea);
                    textarea.select();
                    try {
                        // Execute the copy command
                        document.execCommand('copy');
                        NotificationService.showNotification({
                            type: 'success',
                            message: `Range exportée au format ${formatType} et copiée dans le presse-papier !`,
                        });
                    }
                    catch (err) {
                        // If copy command fails, show the text to the user
                        alert(`Impossible de copier automatiquement. Voici la range au format ${formatType} :\n\n${result}`);
                    }
                    finally {
                        document.body.removeChild(textarea);
                    }
                }
            }
            catch (err) {
                // If all methods fail, show the text to the user
                alert(`Impossible de copier automatiquement. Voici la range au format ${formatType} :\n\n${result}`);
            }
        });
    }
    static exportToPioSolver(handsWithActiveColor) {
        // Définir l'ordre des cartes pour le tri
        const cardOrder = 'AKQJT98765432';
        // Fonction de comparaison pour trier les mains par force
        const compareHands = (a, b) => {
            // Extraire les cartes et le type (s/o/pas de suffixe)
            const [cardsA, typeA = ''] = a.hand.split(/([so])/).filter(Boolean);
            const [cardsB, typeB = ''] = b.hand.split(/([so])/).filter(Boolean);
            // Si les deux premières cartes sont identiques
            if (cardsA[0] === cardsB[0]) {
                // Si l'une des mains est une paire
                if (cardsA[1] === cardsA[0])
                    return -1;
                if (cardsB[1] === cardsB[0])
                    return 1;
                // Comparer les deuxièmes cartes
                if (cardsA[1] !== cardsB[1]) {
                    return cardOrder.indexOf(cardsA[1]) - cardOrder.indexOf(cardsB[1]);
                }
                // Si les cartes sont identiques, suited > offsuited
                if (typeA === 's' && typeB === 'o')
                    return -1;
                if (typeA === 'o' && typeB === 's')
                    return 1;
            }
            // Comparer les premières cartes
            return cardOrder.indexOf(cardsA[0]) - cardOrder.indexOf(cardsB[0]);
        };
        // Trier les mains
        const sortedHands = [...handsWithActiveColor].sort((a, b) => {
            // Si l'une des fréquences est 100, elle passe en premier
            if (a.frequency === 100 && b.frequency !== 100)
                return -1;
            if (b.frequency === 100 && a.frequency !== 100)
                return 1;
            // Sinon, trier par force de main
            return compareHands(a, b);
        });
        // Construire la chaîne de sortie
        const parts = sortedHands.map(({ hand, frequency }) => {
            if (frequency === 100) {
                return hand;
            }
            return `${hand}:${frequency / 100}`;
        });
        return parts.join(',');
    }
    static exportToGTOWizard(handsWithActiveColor) {
        const result = [];
        // Pour chaque main, générer toutes les combinaisons de cartes spécifiques
        handsWithActiveColor.forEach(({ hand, frequency }) => {
            const specificCombinations = this.expandToSpecificHands(hand);
            specificCombinations.forEach(combo => {
                result.push(`${combo}: ${frequency / 100}`);
            });
        });
        return result.join(',');
    }
    static expandToSpecificHands(standardHand) {
        const combinations = [];
        const suits = ['h', 'd', 'c', 's']; // cœur, carreau, trèfle, pique
        // Extraire les valeurs et le type (s/o/pas de suffixe)
        const match = standardHand.match(/^([AKQJT98765432]{2})([so]?)$/);
        if (!match)
            return [];
        const [_, cards, type] = match;
        const card1 = cards[0];
        const card2 = cards[1];
        // Cas des paires (ex: "AA")
        if (card1 === card2) {
            // Générer toutes les combinaisons possibles (6 pour une paire)
            for (let i = 0; i < suits.length; i++) {
                for (let j = i + 1; j < suits.length; j++) {
                    combinations.push(`${card1}${suits[i]}${card2}${suits[j]}`);
                }
            }
        }
        // Cas des mains suited (ex: "AKs")
        else if (type === 's') {
            // Générer toutes les combinaisons de même couleur (4 pour une main suited)
            suits.forEach(suit => {
                combinations.push(`${card1}${suit}${card2}${suit}`);
            });
        }
        // Cas des mains offsuited (ex: "AKo")
        else if (type === 'o' || type === '') {
            // Générer toutes les combinaisons de couleurs différentes (12 pour une main offsuited)
            suits.forEach(suit1 => {
                suits.forEach(suit2 => {
                    if (suit1 !== suit2) {
                        combinations.push(`${card1}${suit1}${card2}${suit2}`);
                    }
                });
            });
        }
        return combinations;
    }
    static exportToFlopZilla(handsWithActiveColor) {
        // Regrouper les mains par fréquence
        const handsByFrequency = new Map();
        handsWithActiveColor.forEach(({ hand, frequency }) => {
            const hands = handsByFrequency.get(frequency) || [];
            hands.push(hand);
            handsByFrequency.set(frequency, hands);
        });
        // Construire la chaîne de sortie
        const parts = [];
        // D'abord les mains avec 100%
        const fullHands = handsByFrequency.get(100) || [];
        if (fullHands.length > 0) {
            parts.push(fullHands.join(','));
        }
        // Puis les mains avec d'autres fréquences
        handsByFrequency.forEach((hands, frequency) => {
            if (frequency !== 100 && hands.length > 0) {
                parts.push(`[${Math.round(frequency)}]${hands.join(',')}[/${Math.round(frequency)}]`);
            }
        });
        return parts.join(',');
    }
    static exportToEquilab(handsWithActiveColor) {
        NotificationService.showNotification({
            type: 'info',
            message: 'Le format Equilab ne supporte pas les fréquences partielles. Toutes les mains seront exportées avec une fréquence de 100%.',
            timeout: 10000,
            closable: true,
        });
        // Ne retenir que les mains avec 100% pour Equilab car il ne supporte pas les fréquences partielles
        const fullHands = handsWithActiveColor
            .filter(({ frequency }) => frequency > 0)
            .map(({ hand }) => hand);
        // Optimiser la liste en regroupant les mains en plages quand c'est possible
        const optimizedRanges = this.optimizeEquilabRanges(fullHands);
        return optimizedRanges.join(',');
    }
    static optimizeEquilabRanges(hands) {
        const cardOrder = 'AKQJT98765432';
        const result = [];
        // Créer des copies pour ne pas modifier les originaux
        const remainingHands = [...hands];
        // Traiter les paires
        const pairs = remainingHands.filter(hand => hand.length === 2);
        if (pairs.length > 0) {
            // Trier les paires par ordre de force
            pairs.sort((a, b) => cardOrder.indexOf(a[0]) - cardOrder.indexOf(b[0]));
            let currentRange = [pairs[0]];
            for (let i = 1; i < pairs.length; i++) {
                const prevPair = pairs[i - 1];
                const currPair = pairs[i];
                const prevIndex = cardOrder.indexOf(prevPair[0]);
                const currIndex = cardOrder.indexOf(currPair[0]);
                // Si les paires sont consécutives
                if (prevIndex + 1 === currIndex) {
                    currentRange.push(currPair);
                }
                else {
                    // Finir la plage actuelle
                    if (currentRange.length > 1) {
                        result.push(`${currentRange[0]}+`);
                    }
                    else {
                        result.push(currentRange[0]);
                    }
                    // Démarrer une nouvelle plage
                    currentRange = [currPair];
                }
            }
            // Ajouter la dernière plage
            if (currentRange.length > 1) {
                result.push(`${currentRange[0]}+`);
            }
            else if (currentRange.length === 1) {
                result.push(currentRange[0]);
            }
            // Retirer les paires traitées
            pairs.forEach(p => {
                const index = remainingHands.indexOf(p);
                if (index !== -1) {
                    remainingHands.splice(index, 1);
                }
            });
        }
        // Traiter les mains suited
        const suitedHands = remainingHands.filter(hand => hand.endsWith('s'));
        // Regrouper par première carte
        const suitedByFirstCard = new Map();
        suitedHands.forEach(hand => {
            const firstCard = hand[0];
            const hands = suitedByFirstCard.get(firstCard) || [];
            hands.push(hand);
            suitedByFirstCard.set(firstCard, hands);
        });
        // Traiter chaque groupe
        suitedByFirstCard.forEach((hands, firstCard) => {
            hands.sort((a, b) => cardOrder.indexOf(a[1]) - cardOrder.indexOf(b[1]));
            let currentRange = [hands[0]];
            for (let i = 1; i < hands.length; i++) {
                const prevHand = hands[i - 1];
                const currHand = hands[i];
                const prevIndex = cardOrder.indexOf(prevHand[1]);
                const currIndex = cardOrder.indexOf(currHand[1]);
                // Si les mains sont consécutives
                if (prevIndex + 1 === currIndex) {
                    currentRange.push(currHand);
                }
                else {
                    // Finir la plage actuelle
                    if (currentRange.length > 1) {
                        result.push(`${currentRange[currentRange.length - 1]}+`);
                    }
                    else {
                        result.push(currentRange[0]);
                    }
                    // Démarrer une nouvelle plage
                    currentRange = [currHand];
                }
            }
            // Ajouter la dernière plage
            if (currentRange.length > 1) {
                result.push(`${currentRange[currentRange.length - 1]}+`);
            }
            else if (currentRange.length === 1) {
                result.push(currentRange[0]);
            }
            // Retirer les mains traitées
            hands.forEach(h => {
                const index = remainingHands.indexOf(h);
                if (index !== -1) {
                    remainingHands.splice(index, 1);
                }
            });
        });
        // Traiter les mains offsuited de la même manière
        const offsuitedHands = remainingHands.filter(hand => hand.endsWith('o'));
        // Regrouper par première carte
        const offsuitedByFirstCard = new Map();
        offsuitedHands.forEach(hand => {
            const firstCard = hand[0];
            const hands = offsuitedByFirstCard.get(firstCard) || [];
            hands.push(hand);
            offsuitedByFirstCard.set(firstCard, hands);
        });
        // Traiter chaque groupe
        offsuitedByFirstCard.forEach((hands, firstCard) => {
            hands.sort((a, b) => cardOrder.indexOf(a[1]) - cardOrder.indexOf(b[1]));
            let currentRange = [hands[0]];
            for (let i = 1; i < hands.length; i++) {
                const prevHand = hands[i - 1];
                const currHand = hands[i];
                const prevIndex = cardOrder.indexOf(prevHand[1]);
                const currIndex = cardOrder.indexOf(currHand[1]);
                // Si les mains sont consécutives
                if (prevIndex + 1 === currIndex) {
                    currentRange.push(currHand);
                }
                else {
                    // Finir la plage actuelle
                    if (currentRange.length > 1) {
                        result.push(`${currentRange[currentRange.length - 1]}+`);
                    }
                    else {
                        result.push(currentRange[0]);
                    }
                    // Démarrer une nouvelle plage
                    currentRange = [currHand];
                }
            }
            // Ajouter la dernière plage
            if (currentRange.length > 1) {
                result.push(`${currentRange[currentRange.length - 1]}+`);
            }
            else if (currentRange.length === 1) {
                result.push(currentRange[0]);
            }
        });
        // Ajouter les mains restantes telles quelles
        remainingHands.forEach(hand => {
            result.push(hand);
        });
        return result;
    }
}
