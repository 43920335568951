import { Controller } from '@hotwired/stimulus';
class ReplayerWidgetController extends Controller {
    connect() {
        this.playInterval = null;
    }
    disconnect() {
        this.pause();
    }
    firstPosition() {
        this.pause();
        this.showPosition(this.getMinPosition());
    }
    previousPosition() {
        this.pause();
        const prevPosition = +this.currentPositionValue - 1;
        if (prevPosition >= this.getMinPosition()) {
            this.showPosition(prevPosition);
        }
    }
    play() {
        this.playButtonTarget.classList.add('hidden');
        this.pauseButtonTarget.classList.remove('hidden');
        this.playInterval = setInterval(() => {
            const nextPosition = +this.currentPositionValue + 1;
            if (nextPosition > this.getMaxPosition()) {
                this.pause();
                return;
            }
            this.showPosition(nextPosition);
        }, +this.delayValue);
    }
    pause() {
        if (this.playInterval != null) {
            clearInterval(this.playInterval);
            this.playInterval = null;
        }
        this.playButtonTarget.classList.remove('hidden');
        this.pauseButtonTarget.classList.add('hidden');
    }
    nextPosition() {
        this.pause();
        const nextPosition = +this.currentPositionValue + 1;
        if (nextPosition <= this.getMaxPosition()) {
            this.showPosition(nextPosition);
        }
    }
    lastPosition() {
        this.pause();
        this.showPosition(this.getMaxPosition());
    }
    showPosition(position) {
        this.situationTargets.forEach((situation) => {
            const situationPosition = parseInt(situation.dataset.position);
            situation.style.display = situationPosition === position ? '' : 'none';
        });
        this.currentPositionValue = position;
        this.currentPositionTarget.innerHTML = (this.currentPositionValue + 1).toString();
    }
    getMinPosition() {
        return Math.min(...this.situationTargets.map(s => parseInt(s.dataset.position)));
    }
    getMaxPosition() {
        return Math.max(...this.situationTargets.map(s => parseInt(s.dataset.position)));
    }
}
ReplayerWidgetController.targets = [
    'situation',
    'currentPosition',
    'playButton',
    'pauseButton',
];
ReplayerWidgetController.values = {
    delay: Number,
    currentPosition: Number
};
export default ReplayerWidgetController;
