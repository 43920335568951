var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
class RouteService {
    constructor() {
        this.routes = {};
        this.isLoaded = false;
    }
    /**
     * Charge les routes Symfony depuis l'API et les stocke localement.
     */
    loadRoutes() {
        return __awaiter(this, void 0, void 0, function* () {
            if (this.isLoaded) {
                return;
            }
            try {
                const response = yield fetch('/api/routes');
                if (!response.ok) {
                    throw new Error(`Erreur lors du chargement des routes: ${response.statusText}`);
                }
                this.routes = yield response.json();
                this.isLoaded = true;
            }
            catch (error) {
                console.error("Impossible de charger les routes Symfony:", error);
            }
        });
    }
    /**
     * Retourne l'URL d'une route donnée.
     * @param name - Nom de la route Symfony
     * @param params
     * @returns L'URL de la route ou un #
     */
    getRoute(name, params = {}) {
        if (!this.routes[name]) {
            console.warn(`Route "${name}" non trouvée.`);
            return '#';
        }
        let url = this.routes[name];
        // Remplacement des paramètres dans la route
        Object.entries(params).forEach(([key, value]) => {
            url = url.replace(new RegExp(`{${key}}`, 'g'), encodeURIComponent(value.toString()));
        });
        // Vérification : s'il reste des paramètres non remplacés, la route est invalide
        if (/{\w+}/.test(url)) {
            console.error(`Certains paramètres requis pour la route "${name}" n'ont pas été fournis.`);
            return '#';
        }
        return url;
    }
}
const routeService = new RouteService();
export default routeService;
