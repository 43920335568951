export class NotificationService {
    static showNotification(params) {
        var _a;
        const notification = document.createElement('div');
        notification.classList.add('custom-toast', `custom-toast-${params.type}`);
        // Créer un conteneur pour le message
        const messageContainer = document.createElement('div');
        messageContainer.classList.add('toast-message');
        messageContainer.innerText = params.message;
        notification.appendChild(messageContainer);
        // Ajouter un bouton de fermeture si demandé
        if (params.closable === true) { // Par défaut, les notifications sont fermables
            const closeButton = document.createElement('button');
            closeButton.classList.add('toast-close-btn');
            closeButton.innerHTML = '&times;';
            closeButton.addEventListener('click', () => {
                this.removeNotification(notification);
            });
            notification.appendChild(closeButton);
        }
        // Positionnement initial
        notification.style.position = 'fixed';
        notification.style.right = '20px';
        notification.style.top = '20px';
        notification.style.zIndex = '10000';
        notification.style.transition = 'all 0.3s ease-in-out';
        // Ajoute la notification au body
        document.body.appendChild(notification);
        // Calcule et applique la position verticale
        this.updateNotificationPositions(notification);
        // Enregistre la notification active
        this.activeNotifications.push(notification);
        // Supprime la notification après le délai spécifié
        const timeout = (_a = params.timeout) !== null && _a !== void 0 ? _a : 3000;
        setTimeout(() => {
            this.removeNotification(notification);
        }, timeout);
    }
    static updateNotificationPositions(newNotification) {
        let currentTop = 20; // Position initiale (en pixels)
        // Parcourt toutes les notifications actives pour les repositionner
        this.activeNotifications.forEach(notif => {
            notif.style.top = `${currentTop}px`;
            currentTop += notif.offsetHeight + this.NOTIFICATION_GAP;
        });
        // Si une nouvelle notification est fournie, positionnez-la également
        if (newNotification && !this.activeNotifications.includes(newNotification)) {
            newNotification.style.top = `${currentTop}px`;
        }
    }
    static removeNotification(notification) {
        notification.classList.add('fade-out');
        setTimeout(() => {
            // Supprime la notification du DOM
            notification.remove();
            // Supprime la notification de notre tableau
            const index = this.activeNotifications.indexOf(notification);
            if (index > -1) {
                this.activeNotifications.splice(index, 1);
            }
            // Repositionne les notifications restantes
            this.updateNotificationPositions();
        }, 500);
    }
}
NotificationService.activeNotifications = [];
NotificationService.NOTIFICATION_GAP = 10; // Espace entre les notifications (en pixels)
