var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Controller } from '@hotwired/stimulus';
import { NotificationService } from "@app/core/notification/NotificationService";
import { RangeGroupService } from "@app/range/RangeGroupService";
class RangeViewerController extends Controller {
    constructor() {
        super(...arguments);
        this.colors = [];
        this.selectedHands = new Map();
    }
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            // Chargement automatique du groupe de ranges s'il est défini
            const rangeGroupId = this.rangeGroupIdValue;
            if (rangeGroupId) {
                yield this.loadRangeGroupById(rangeGroupId);
            }
        });
    }
    /**
     * Charge automatiquement un groupe de ranges spécifié par ID
     */
    loadRangeGroupById(groupId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!groupId)
                return;
            try {
                const result = yield RangeGroupService.getRangeGroup(groupId);
                if (result.success && result.data) {
                    // Nettoyer la sélection actuelle
                    this.selectedHands.clear();
                    // Mettre à jour les mains sélectionnées
                    result.data.hands.forEach((frequencies, hand) => {
                        this.selectedHands.set(hand, frequencies);
                    });
                    // Mettre à jour les couleurs si disponibles
                    if (result.data.colors && result.data.colors.length > 0) {
                        this.colors = result.data.colors;
                    }
                    // Mettre à jour l'affichage
                    this.updateDisplay();
                }
                else {
                    console.error('Erreur lors du chargement du groupe de ranges:', result.message);
                    NotificationService.showNotification({
                        type: 'error',
                        message: result.message || 'Erreur lors du chargement du groupe de ranges',
                    });
                }
            }
            catch (error) {
                console.error('Erreur lors du chargement du groupe de ranges:', error);
                NotificationService.showNotification({
                    type: 'error',
                    message: `Erreur lors du chargement: ${error instanceof Error ? error.message : 'Erreur inconnue'}`,
                });
            }
        });
    }
    updateDisplay() {
        this.cellTargets.forEach((cell, index) => {
            const hand = cell.dataset.hand;
            if (!hand)
                return;
            const frequencies = this.selectedHands.get(hand) || [];
            const fill = this.fillTargets[index];
            const handText = cell.querySelector('.range-viewer__hand-text');
            if (frequencies.length > 0) {
                cell.classList.add('selected');
                const tooltipContent = frequencies
                    .map(f => `${f.name}: ${f.frequency}%`)
                    .join('\n');
                cell.setAttribute('title', tooltipContent);
                // Trier les fréquences par ordre décroissant pour un meilleur affichage
                const sortedFrequencies = [...frequencies].sort((a, b) => b.frequency - a.frequency);
                handText.style.color = '#000000';
                // Création des barres de couleur
                let currentWidth = 0;
                fill.innerHTML = sortedFrequencies.map(f => {
                    const width = f.frequency;
                    const start = currentWidth;
                    currentWidth += width;
                    return `<div 
                        class="range-viewer__fill-segment" 
                        style="
                            background-color: ${f.color}; 
                            left: ${start}%;
                            width: ${width}%;
                            z-index: ${Math.floor(f.frequency)}"
                    ></div>`;
                }).join('');
            }
            else {
                cell.classList.remove('selected');
                cell.removeAttribute('title');
                fill.innerHTML = '';
                handText.style.color = '';
            }
        });
    }
}
RangeViewerController.targets = [
    'cell',
    'fill',
];
RangeViewerController.values = {
    rangeGroupId: { type: Number, default: null }
};
export default RangeViewerController;
