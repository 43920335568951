var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Controller } from '@hotwired/stimulus';
import { RangeGroupService } from "@app/range/RangeGroupService";
class RangeViewerMiniController extends Controller {
    constructor() {
        super(...arguments);
        this.colors = [];
        this.selectedHands = new Map();
        this.miniIsHovered = false;
        this.tooltipIsHovered = false;
    }
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            // Chargement automatique de la miniature dès la connexion
            const rangeGroupId = this.rangeGroupIdValue;
            if (rangeGroupId) {
                yield this.loadRangeGroupMiniById(rangeGroupId);
            }
            // Ajout des écouteurs d'événements pour le mini-viewer
            this.element.addEventListener('mouseenter', this.handleMiniMouseEnter.bind(this));
            this.element.addEventListener('mouseleave', this.handleMiniMouseLeave.bind(this));
            // Ajout des écouteurs d'événements pour le tooltip
            this.tooltipTarget.addEventListener('mouseenter', this.handleTooltipMouseEnter.bind(this));
            this.tooltipTarget.addEventListener('mouseleave', this.handleTooltipMouseLeave.bind(this));
        });
    }
    disconnect() {
        // Nettoyage des écouteurs lors de la déconnexion
        this.element.removeEventListener('mouseenter', this.handleMiniMouseEnter.bind(this));
        this.element.removeEventListener('mouseleave', this.handleMiniMouseLeave.bind(this));
        this.tooltipTarget.removeEventListener('mouseenter', this.handleTooltipMouseEnter.bind(this));
        this.tooltipTarget.removeEventListener('mouseleave', this.handleTooltipMouseLeave.bind(this));
    }
    /**
     * Gère l'entrée de la souris sur le mini-viewer
     */
    handleMiniMouseEnter() {
        this.miniIsHovered = true;
        this.updateTooltipVisibility();
    }
    /**
     * Gère la sortie de la souris du mini-viewer
     */
    handleMiniMouseLeave() {
        this.miniIsHovered = false;
        // Délai court pour permettre à la souris d'atteindre le tooltip
        setTimeout(() => this.updateTooltipVisibility(), 50);
    }
    /**
     * Gère l'entrée de la souris sur le tooltip
     */
    handleTooltipMouseEnter() {
        this.tooltipIsHovered = true;
        this.updateTooltipVisibility();
    }
    /**
     * Gère la sortie de la souris du tooltip
     */
    handleTooltipMouseLeave() {
        this.tooltipIsHovered = false;
        this.updateTooltipVisibility();
    }
    /**
     * Met à jour la visibilité du tooltip en fonction de l'état du survol
     */
    updateTooltipVisibility() {
        if (this.miniIsHovered || this.tooltipIsHovered) {
            this.tooltipTarget.classList.add('active');
        }
        else {
            this.tooltipTarget.classList.remove('active');
        }
    }
    /**
     * Charge la version miniature du groupe de ranges
     */
    loadRangeGroupMiniById(groupId) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!groupId)
                return;
            try {
                const result = yield RangeGroupService.getRangeGroup(groupId);
                if (result.success && result.data) {
                    // Mettre à jour les mains sélectionnées
                    this.selectedHands.clear();
                    result.data.hands.forEach((frequencies, hand) => {
                        this.selectedHands.set(hand, frequencies);
                    });
                    // Mettre à jour les couleurs si disponibles
                    if (result.data.colors && result.data.colors.length > 0) {
                        this.colors = result.data.colors;
                    }
                    // Mettre à jour l'affichage de la miniature
                    this.updateMiniDisplay();
                }
                else {
                    console.error('Erreur lors du chargement de la miniature:', result.message);
                }
            }
            catch (error) {
                console.error('Erreur lors du chargement de la miniature:', error);
            }
        });
    }
    /**
     * Met à jour l'affichage de la miniature
     */
    updateMiniDisplay() {
        this.cellTargets.forEach((cell) => {
            const hand = cell.dataset.hand;
            if (!hand)
                return;
            const frequencies = this.selectedHands.get(hand) || [];
            if (frequencies.length > 0) {
                // Marquer la cellule comme sélectionnée
                cell.classList.add('selected');
                // Utiliser la couleur dominante (celle avec la plus grande fréquence)
                const dominantColor = [...frequencies].sort((a, b) => b.frequency - a.frequency)[0];
                // Appliquer la couleur dominante comme arrière-plan
                cell.style.backgroundColor = dominantColor.color;
            }
            else {
                cell.classList.remove('selected');
                cell.style.backgroundColor = '';
            }
        });
    }
}
RangeViewerMiniController.targets = [
    'cell',
    'tooltip'
];
RangeViewerMiniController.values = {
    rangeGroupId: { type: Number, default: null }
};
export default RangeViewerMiniController;
