var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Controller } from '@hotwired/stimulus';
import axios from "axios";
import PreviewController from "@controllers/admin/preview/p_controller";
class AdminTableCrudController extends Controller {
    constructor() {
        super(...arguments);
        this.DEFAULT_STACK = "100";
    }
    connect() {
        return __awaiter(this, void 0, void 0, function* () {
            this.playerAddButtonSelector =
                'div[data-admin--table--crud-target="players"] > .form-widget > .field-collection-add-button';
            this.playerItemsSelector = 'div.field-collection-item';
            this.previewController = PreviewController.getController();
            this.updatePreview();
            yield this.updatePossiblePositions();
            document.querySelectorAll(this.playerAddButtonSelector).forEach((button) => {
                button.addEventListener('click', (event) => __awaiter(this, void 0, void 0, function* () {
                    yield this.updatePossiblePositions();
                    this.updatePreview();
                }));
            });
        });
    }
    updatePreview() {
        if (this.previewController) {
            this.previewController.updatePreview();
        }
    }
    updatePossiblePositions() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hasMaxPlayersTarget || !this.hasPositionSelectTarget) {
                return;
            }
            const maxPlayers = this.maxPlayersTarget.value;
            const updateUrl = this.maxPlayersTarget.dataset.url;
            try {
                const response = yield axios.get(`${updateUrl}/${maxPlayers}`);
                const positions = response.data.positions;
                this.positionSelectTargets.forEach((select, selectIndex) => {
                    const selectedValue = selectIndex + 1;
                    select.innerHTML = "";
                    positions.forEach((position) => {
                        const option = document.createElement("option");
                        option.value = position.value.toString();
                        option.textContent = position.label;
                        select.appendChild(option);
                        if (position.value === +selectedValue) {
                            option.selected = true;
                        }
                    });
                });
            }
            catch (error) {
                console.error("Error fetching positions:", error);
            }
        });
    }
    updateBlindsBet() {
        if (this.playerItemsSelector === undefined) {
            return;
        }
        document.querySelectorAll(this.playerItemsSelector).forEach((player, index) => {
            const playerPosition = document
                .getElementById("TableSituation_players_" + index + "_position");
            const playerBet = document
                .getElementById("TableSituation_players_" + index + "_mise");
            const playerStack = document
                .getElementById("TableSituation_players_" + index + "_stack");
            if (!(playerPosition instanceof HTMLElement) ||
                !(playerBet instanceof HTMLElement) ||
                !(playerStack instanceof HTMLElement)) {
                throw new Error('HTMLElement should not be null');
            }
            if (+playerPosition.value === 1 && +playerBet.value < 0.5) {
                playerBet.value = "0.5";
            }
            if (+playerPosition.value === 2 && +playerBet.value < 1) {
                playerBet.value = "1";
            }
            if (+playerStack.value === 0) {
                playerStack.value = this.DEFAULT_STACK;
            }
        });
    }
    adjustPlayers() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.hasMaxPlayersTarget ||
                !this.hasPlayersTarget ||
                this.playerItemsSelector == null ||
                this.playerAddButtonSelector == null) {
                throw new Error('HTMLElement should not be null');
            }
            const maxPlayers = this.maxPlayersTarget.value;
            const playersContainer = this.playersTarget;
            const currentPlayers = playersContainer.querySelectorAll(this.playerItemsSelector).length;
            // Add players if current is less than maxPlayers
            if (currentPlayers < +maxPlayers) {
                const playerAddButton = document.querySelector(this.playerAddButtonSelector);
                if (playerAddButton === null) {
                    throw new Error('HTMLElement should not be null');
                }
                for (let i = currentPlayers; i < +maxPlayers; i++) {
                    playerAddButton.click();
                }
            }
            yield this.updatePossiblePositions();
            this.updateBlindsBet();
            if (this.previewController) {
                this.previewController.updatePreview();
            }
        });
    }
}
AdminTableCrudController.targets = [
    'positionSelect',
    'maxPlayers',
    'players',
];
export default AdminTableCrudController;
