var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import { NotificationService } from "@app/core/notification/NotificationService";
import { LoaderService } from "@app/core/loader/LoaderService";
export class HttpService {
    static showLoader() {
        LoaderService.show();
    }
    static hideLoader() {
        LoaderService.hide();
    }
    static request(params) {
        return __awaiter(this, void 0, void 0, function* () {
            const { url, method, queryParams, payload, successMessage, errorMessage, showLoader } = params;
            if (showLoader) {
                this.showLoader();
            }
            try {
                const config = {
                    url,
                    method,
                    params: queryParams,
                    data: payload,
                };
                const response = yield this.axiosInstance.request(config);
                if (successMessage) {
                    NotificationService.showNotification({
                        type: 'success',
                        message: successMessage,
                    });
                }
                return { result: response.data, error: null };
            }
            catch (error) {
                if (errorMessage) {
                    NotificationService.showNotification({
                        type: 'error',
                        message: errorMessage,
                    });
                }
                console.error('HTTP request error:', error);
                return { result: null, error: error };
            }
            finally {
                if (showLoader) {
                    this.hideLoader();
                }
            }
        });
    }
}
HttpService.axiosInstance = axios.create({
    baseURL: '', // Ajoutez ici une baseURL si nécessaire
    timeout: 60000, // Timeout de 10 secondes par défaut
});
// Exemple d'utilisation
// (async () => {
//     const { result, error } = await HttpService.request<{ data: string[] }>({
//         url: 'https://api.example.com/resource',
//         method: 'GET',
//         queryParams: { search: 'query' },
//         successMessage: 'Requête réussie avec succès !',
//         errorMessage: 'Échec de la requête.',
//         showLoader: true,
//     });
//
//     if (error) {
//         console.error('Erreur :', error);
//     } else {
//         console.log('Réponse :', result);
//     }
// })();
